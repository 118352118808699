import { PortraitSharp } from "@material-ui/icons";
import React from "react";
import { CountryNames } from "../Data/CountryList";

const CountrySelect = (props) => {
  return (
    <select
      name={props.name}
      key={props.name}
      value={props.value}
      onChange={props.onChange}
    >
      {CountryNames.map((country) => (
        <option value={country.name} key={country.name}>
          {country.name}
        </option>
      ))}
    </select>
  );
};

export default CountrySelect;
