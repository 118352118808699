import React from "react";
import ListIcon from "@material-ui/icons/List";
import "../css/DomainHead.css";

const DomainHead = ({ title }) => {
  return (
    <div className="domain-head">
      {/* <h2>Sending Identity</h2>
      <div className="domain-menuhead">
        <div className="domain-menuhead-item">
          <MailOutlineIcon className="domain-menuhead-item-icon" />
          <p>Email Address</p>
        </div>
        <div className="domain-menuhead-item active">
          <PublicIcon className="domain-menuhead-item-icon" />
          <p>Domains</p>
        </div>
      </div>
      <div className="horizontal-line"></div> */}
      <div className="domain-container-bodyhead">
        <ListIcon className="list-icon" />
        <h2>{title}</h2>
      </div>
      <p className="domain-paragraph">
        Sending domain is used to verify the sender whose email address
        appearing in the FROM header of an email. Add your own verified sending
        domains to send emails on your or your organization's behalf.
      </p>
    </div>
  );
};

export default DomainHead;
