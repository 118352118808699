import React from "react";
import { toast, ToastContainer } from "react-toastify";
import myAxiosGlobal from "../MyAxios/MyAxiosGlobal";

const SignUp = ({
  name,
  setName,
  email,
  setEmail,
  password,
  setPassword,
  loading,
  setLoading,
  required,
  setSignin,
  setIsOpen,
  setThankuModal,
}) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    if (name === "" || email === "" || password === "") {
      return toast.error("Please fill all the fields");
    }
    setLoading(true);
    myAxiosGlobal
      .post("/api/signup", { name: name, email: email, password: password })
      .then((response) => {
        console.log(response);
        localStorage.setItem("vmtoken", response.data.refreshToken);
        localStorage.setItem("vmuser", response.data.user.email);
        localStorage.setItem("vmid", response.data.user._id);
        localStorage.setItem("signupemailsend", 0);
        localStorage.setItem("countVerify", 2);
        setLoading(false);
        setIsOpen(false);
        toast.success("Registration success");
        setThankuModal(true);
        setName("");
        setEmail("");
        setPassword("");
      })
      .catch((error) => {
        toast.error(error?.response?.data);
        toast.error(error?.response?.data?.message);
        setLoading(false);
      });
  };
  return (
    <div className="modal-body">
      <form className="paymentFrm">
        <div className="form-group">
          <label>Name</label>
          <input
            type="text"
            placeholder="Name"
            className={
              name === "" && required
                ? "form-control red-error-border"
                : "form-control"
            }
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Email</label>
          <input
            type="text"
            placeholder="Email"
            className={
              email === "" && required
                ? "form-control red-error-border"
                : "form-control"
            }
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Password</label>
          <input
            type="password"
            placeholder="Password"
            className={
              password === "" && required
                ? "form-control red-error-border"
                : "form-control"
            }
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <div className="form-group">
          <button
            type="submit"
            className={
              loading
                ? "payment-success-button payment-success-button-signup disabled"
                : "payment-success-button payment-success-button-signup"
            }
            onClick={handleSubmit}
          >
            {loading ? "Loading.." : "SignUp Now!"}
          </button>
        </div>
        <div className="account-footer">
          Already Have an Account?{" "}
          <span onClick={() => setSignin(true)}>SIGN IN</span>
        </div>
      </form>
    </div>
  );
};

export default SignUp;
