import React, { Fragment, useEffect } from "react";
import Banner from "./Banner";
import Footer from "./Footer";
import Header from "./Header";
import PreFooter from "./PreFooter";
import ServicesSection from "./ServicesSection";
import VerifySection from "./VerifySection";
import AdSense from "react-adsense";

import "./Home.css";

const Home = () => {
  useEffect(() => {
    document.title = `100% Free Email Verifier Tools | Free Email Checker & Email Verification Tool Online | Bulk Email Verifier | Email Verifier Api | 1 Click To Verify `;
  }, []);
  return (
    <Fragment>
      <Header />
      {/* <AdSense.Google
        client="ca-pub-1782749546891141"
        slot="5351171120"
        style={{ display: "block" }}
        format="auto"
        responsive="true"
        layoutKey="-gw-1+2a-9x+5c"
      /> */}
      <Banner />

      <ServicesSection />
      <VerifySection />
      <PreFooter />
      <Footer />
    </Fragment>
  );
};

export default Home;
