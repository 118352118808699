import React, { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import myAxios from "../MyAxios/MyAxios";
const CreateProjectModal = ({ setCreateProjectModal }) => {
  const [projectName, setProjectName] = useState("");
  const [loading, setLoading] = useState(false);
  const createProject = (e) => {
    e.preventDefault();
    setLoading(true);
    myAxios
      .post("/api/create-project", {
        projectName,
        userId: localStorage.getItem("vmid"),
      })
      .then((res) => {
        console.log("res", res);
        setCreateProjectModal(false);
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setCreateProjectModal(false);
        setLoading(false);
      });
  };
  return (
    <div className="create-project-modal-container">
      <div className="modal-close" onClick={() => setCreateProjectModal(false)}>
        <CloseIcon />
      </div>
      <form className="create-project-form" onSubmit={createProject}>
        <label>Project Name</label>
        <input
          type="text"
          name="project-name"
          placeholder="Project Name"
          value={projectName}
          onChange={(e) => setProjectName(e.target.value)}
        />
        <button type="submit" className="email-create-btn">
          {loading ? "Loading.." : "Create Project"}
        </button>
      </form>
    </div>
  );
};

export default CreateProjectModal;
