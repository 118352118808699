import React, { useState, useEffect, useContext } from "react";
import myAxios from "../MyAxios/MyAxios";
import PublishIcon from "@material-ui/icons/Publish";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import ProfileHead from "./ProfileHead";
import TimezoneSelect from "./SelectOptions/TimezoneSelect";
import LanguageSelect from "./SelectOptions/LanguageSelect";
import "./MyProfile.css";
import "./ContactInfo.css";
import { UserContext } from "../../contexts/UserProvider";
import AvatarImage from "../../images/avatar.png";
import { toast, ToastContainer } from "react-toastify";
import Modal from "react-modal";
import ChangePassModal from "./ChangePassModal";

const MyProfile = () => {
  const { getUser, userData } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [passModal, setPassModal] = useState(false);

  useEffect(() => {
    document.title = `Profile | Free Email Verifier`;
    getUser();
  }, []);

  const [photo, setPhoto] = useState(null);
  const [firstName, setFirstName] = useState(
    `${userData?.profile_firstname ? userData?.profile_firstname : ""}`
  );
  const [lastName, setLastName] = useState(
    `${userData.profile_lastname ? userData.profile_lastname : ""}`
  );
  const [email, setEmail] = useState(`${userData.email ? userData.email : ""}`);

  const ImageChange = (event) => {
    setPhoto(event.target.files[0]);
  };

  const resetFile = (event) => {
    setPhoto("");
  };

  const submitProfileData = (e) => {
    setLoading(true);
    e.preventDefault();

    const profileData = {
      profile_firstname: firstName,
      profile_lastname: lastName,
      email: email,
    };

    myAxios
      .post(`/api/update/profile/${localStorage.getItem("vmid")}`, profileData)
      .then((response) => {
        toast.success(response.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.response.data);
        setLoading(false);
      });
  };

  return (
    <div className="profile-main-container">
      <ToastContainer />
      <ProfileHead
        firstName={userData.profile_firstname}
        lastName={userData.profile_lastname}
      />
      <form onSubmit={submitProfileData} encType="multipart/form-data">
        <div className="profile-content-container">
          <div className="profile-content-left">
            <h2 className="contact-info-title">Profile Photo</h2>
            <div className="profile-container">
              <img src={photo ? photo.name : AvatarImage} alt="profile-image" />
              <h2>Upload your photo…</h2>
              <p>Photo should be at least 300px x 300px</p>
              <div className="btn-group">
                <button
                  className="profile-btn profile-upload"
                  style={{ position: "relative" }}
                >
                  <PublishIcon />{" "}
                  <input
                    type="file"
                    name="photo"
                    onChange={ImageChange}
                    style={{
                      position: "absolute",
                      left: 0,
                      opacity: 0,
                      width: "90px",
                    }}
                  />
                  upload
                </button>
                <button
                  className="profile-btn profile-remove"
                  onClick={resetFile}
                >
                  <DeleteIcon />
                  Remove
                </button>
              </div>
            </div>
          </div>
          <div className="profile-content-middle">
            <h2 className="contact-info-title">Basic information</h2>

            <div className="input-group">
              <label>
                First Name <span>*</span>
              </label>
              <input
                className="firstname"
                name="firstName"
                type="text"
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>

            <div className="input-group">
              <label>
                Email <span>*</span>
              </label>
              <input
                className="email"
                name="email"
                type="text"
                placeholder="example@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="profile-content-right">
            <h2 className="contact-info-title">Account</h2>

            <div className="input-group">
              <label>
                Last Name <span>*</span>
              </label>
              <input
                className="lastname"
                name="lastName"
                type="text"
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className="input-group">
              <label>
                Language <span>*</span>
              </label>
              <LanguageSelect name="language" />
            </div>
            <button
              className="form-button"
              style={{ marginTop: "20px", padding: "10px 20px" }}
              onClick={() => setPassModal(!passModal)}
            >
              Change Password
            </button>
          </div>
        </div>
        <button type="submit" className="form-button">
          <SaveAltIcon /> {loading ? "Loading" : "Save"}
        </button>
      </form>

      <Modal
        ariaHideApp={false}
        style={{ overlay: { background: "rgba(0,0,0,0.9)" } }}
        className="modal-class-package"
        isOpen={passModal}
        onRequestClose={() => setPassModal(false)}
      >
        <ChangePassModal userData={userData} setPassModal={setPassModal} />
      </Modal>
    </div>
  );
};

export default MyProfile;
