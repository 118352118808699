import axios from "axios";
import React, { createContext, useState } from "react";
import { useHistory } from "react-router-dom";
import myAxios from "../componends/MyAxios/MyAxios";
import myAxiosGlobal from "../componends/MyAxios/MyAxiosGlobal";

export const LoginContext = createContext();

const LoginProvider = (props) => {
  let history = useHistory();
  const [error, setError] = useState("");

  const handleLogin = (
    email,
    password,
    setLoading,
    setErrorMessage,
    setSuccessMessage
  ) => {
    setError("");
    setLoading(true);
    myAxiosGlobal
      .post("/api/login", {
        email: email,
        password: password,
      })
      .then(function (response) {
        localStorage.setItem("vmtoken", response.data.refreshToken);
        localStorage.setItem("vmuser", response.data.user.email);
        localStorage.setItem("vmid", response.data.user._id);
        localStorage.setItem("isSignup", true);
        setSuccessMessage("Login Successful");
        window.location.href = "/dashboard";
        setLoading(false);
      })
      .catch(function (error) {
        setErrorMessage(error);
        setLoading(false);
      });
  };

  const verifyToken = async () => {
    try {
      if (localStorage.getItem("vmtoken")) {
        await myAxios.post("/api/verifytoken");
        history.push("/dashboard");
      } else return;
    } catch (error) {
      history.push("/");
    }
  };

  return (
    <LoginContext.Provider
      value={{ handleLogin, error, setError, verifyToken }}
    >
      {props.children}
    </LoginContext.Provider>
  );
};

export default LoginProvider;
