import React from "react";
import CloseIcon from "@material-ui/icons/Close";

const UploadEmail = ({ setUploadEmail }) => {
  return (
    <div className="create-project-modal-container">
      <div className="modal-close" onClick={() => setUploadEmail(false)}>
        <CloseIcon />
      </div>
      <div className="add-email-heading">
        <h2>Copy your email and Paste below</h2>
        <p>
          You can copy and paste multiple email from your email file and paste
          here line by line
        </p>
      </div>
      <form className="create-project-form">
        <textarea
          type="text"
          name="project-email"
          cols={30}
          rows={10}
          placeholder="example@example.com
example@example.com
example@example.com
example@example.com"
        ></textarea>
        <button type="submit" className="email-create-btn">
          Add Email
        </button>
      </form>
    </div>
  );
};

export default UploadEmail;
