import React, { useEffect, useState, useContext } from "react";
import "../css/DashboardMain.css";
import myAxios from "../MyAxios/MyAxios";
import CloseIcon from "@material-ui/icons/Close";
import { toast, ToastContainer } from "react-toastify";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../contexts/UserProvider";

const DashboardNotification = ({
  userData,
  domainList,
  token,
  setNotification,
}) => {
  const history = useHistory();

  const [loadingresendemail, setLoadingResendemail] = useState(false);
  const [loadingaddDomain, setLoadingAddDomain] = useState(false);
  const [toggleAddDomain, setToggleAddDomain] = useState(true);
  const [toggleNotification, setToggleNotification] = useState(false);
  const [toggleSubscription, setToggleSubscription] = useState(false);

  console.log("domainList", domainList.length);

  const ResendEmail = (e) => {
    e.preventDefault();
    setLoadingResendemail(true);
    myAxios
      .post("/api/user/send_email_verify", {
        user_id: localStorage.getItem("vmid"),
      })
      .then((response) => {
        toast.success(response.data);
        return setLoadingResendemail(false);
      })
      .catch((error) => {
        toast.error(error.response.data);
        return setLoadingResendemail(false);
      });
  };

  useEffect(() => {
    if (userData.activate === false) {
      setToggleNotification(true);
    }

    if (domainList.length > 0) {
      setToggleAddDomain(false);
    }

    if (!token) {
      setToggleSubscription(true);
    }
  }, [userData.activate]);

  return (
    <div>
      <ToastContainer />
      <div
        className={` ${
          !token ? "single-notification-content-body" : "hide-notification"
        }`}
      >
        {toggleSubscription && (
          <div
            className={`${token ? "hide-notification" : "notification-area "}`}
            style={{ marginTop: "4px" }}
          >
            <p>
              {!token && "You need to subscribe a plan to send email"}

              {!token && (
                <button
                  className="resend-email"
                  onClick={() => {
                    setNotification(false);
                    history.push("/billing");
                  }}
                >
                  {loadingaddDomain ? "LOADING..." : "SUBSCRIBE"}
                </button>
              )}
            </p>
            <span>
              <CloseIcon
                className="notification-close-icon"
                onClick={() => setToggleSubscription(false)}
              />
            </span>
          </div>
        )}
      </div>

      <div
        className={` ${
          userData?.activate === false
            ? "single-notification-content-body"
            : "hide-notification"
        }`}
      >
        {toggleNotification && (
          <div
            className={`${
              userData.activate ? "hide-notification" : "notification-area"
            }`}
          >
            <ToastContainer className="toast-notification" />
            <p>
              {userData?.activate === false
                ? "Your email address is unverified. We have send you a verification email. Please check your inbox or  "
                : ""}
              {userData?.activate === false && (
                <button className="resend-email" onClick={ResendEmail}>
                  {loadingresendemail ? "LOADING..." : "RESEND EMAIL"}
                </button>
              )}
            </p>
            <span>
              <CloseIcon
                className="notification-close-icon"
                onClick={() => setToggleNotification(false)}
              />
            </span>
          </div>
        )}
      </div>

      <div
        className={` ${
          domainList.length === 0
            ? "single-notification-content-body"
            : "hide-notification"
        }`}
      >
        {toggleAddDomain && (
          <div
            className={`${
              domainList.length > 0 ? "hide-notification" : "notification-area"
            }`}
            style={{ marginTop: "4px" }}
          >
            <ToastContainer />
            <p>
              {domainList.length === 0 &&
                "You don't have any sending domain. Sending email is required to send email"}

              {domainList.length === 0 && (
                <button
                  className="resend-email"
                  onClick={() => {
                    setNotification(false);
                    history.push("/sending_domain");
                  }}
                >
                  {loadingaddDomain ? "LOADING..." : "ADD DOMAIN"}
                </button>
              )}
            </p>
            <span>
              <CloseIcon
                className="notification-close-icon"
                onClick={() => setToggleAddDomain(false)}
              />
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardNotification;
