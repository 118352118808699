import React, { useContext, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MenuIcon from "@material-ui/icons/Menu";
import EmailIcon from "@material-ui/icons/Email";
import SmsIcon from "@material-ui/icons/Sms";
import DescriptionIcon from "@material-ui/icons/Description";
import NotificationsIcon from "@material-ui/icons/Notifications";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SchoolIcon from "@material-ui/icons/School";
import AddCreditModal1 from "./AddCreditModal1";
import Modal from "react-modal";
import Notification from "./Notification";
import { NavLink } from "react-router-dom";
import "../css/DashboardMainNav.css";

import { ToggleContext } from "../../contexts/ToggleProvider";
import { UserContext } from "../../contexts/UserProvider";
import userImage from "../../images/user.png";

const DashboardMainNav = ({ amount }) => {
  const history = useHistory();
  let myRef = useRef("");

  const {
    toggleMobile,
    setToggleMobile,
    notification,
    setNotification,
    popUpSocial,
    setPopUpSocial,
    popUpUser,
    setPopUpUser,
    packageModal,
    setPackageModal,
    addCreditModalUpdate,
    setAddCreditModalUpdate,
  } = useContext(ToggleContext);

  const {
    email,
    emailCredit,
    packageName,
    validity,
    userData,
    notificationCount,
    totalNotificationArray,
    token,
    domainList,
    getUser,
    getNotificationCount,
  } = useContext(UserContext);

  useEffect(() => {
    let handlerUser = (event) => {
      if (!myRef.current.contains(event.target)) {
        setPopUpUser(false);
      }
    };

    document.addEventListener("mousedown", handlerUser);
    return () => {
      document.removeEventListener("mousedown", handlerUser);
    };
  });

  useEffect(() => {
    let handler = (event) => {
      if (!myRef.current.contains(event.target)) {
        setPopUpSocial(false);
      }
    };

    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const logoutHandler = (e) => {
    e.preventDefault();
    localStorage.removeItem("vmtoken");
    localStorage.removeItem("vmuser");
    localStorage.removeItem("vmid");
    localStorage.setItem("countVerify", 0);
    localStorage.removeItem("isSignup");
    localStorage.removeItem("signupemailsend");
    history.push("/");
  };

  const formatDate = () => {
    return new Date(validity).toLocaleString("en-US");
  };

  return (
    <div className="dashboard-main-nav">
      <div
        className="dashboard-main-nav-left"
        onClick={() => setPopUpSocial((popUpSocial) => !popUpSocial)}
      >
        <EmailIcon className="email-icon" />
        <span>EMAIL</span>
        <ExpandMoreIcon className="nav-icons" />
        <div
          className={
            popUpSocial
              ? "popup-box social-box social-active"
              : "popup-box social-box"
          }
        >
          <div className="message-box" ref={myRef}>
            <SmsIcon />
            <p> SMS </p>
          </div>
        </div>
      </div>

      <div className="dashboard-main-nav-right">
        <div className="email-ammount">
          <div
            className="add-money"
            onClick={() => setAddCreditModalUpdate(!addCreditModalUpdate)}
          >
            <a href="#">add credit</a>
          </div>
        </div>

        <Modal
          ariaHideApp={false}
          style={{ overlay: { background: "rgba(0,0,0,0.9)" } }}
          className="modal-class-package"
          isOpen={addCreditModalUpdate}
          onRequestClose={() => setAddCreditModalUpdate(false)}
        >
          <AddCreditModal1
            userData={userData}
            setAddCreditModalUpdate={setAddCreditModalUpdate}
          />
        </Modal>

        <div
          ref={myRef}
          className="user-popup"
          onClick={() => setPopUpUser((popUpUser) => !popUpUser)}
        >
          <img src={userImage} alt="userImg" />
          <ExpandMoreIcon className="nav-icons" />
        </div>

        <div
          className={
            popUpUser ? "popup-box user-box user-active" : "popup-box user-box"
          }
        >
          <div className="user-box" ref={myRef}>
            <p>Signed in as</p>
            <h4>{amount && amount.email}</h4>

            <button className="btn btn-monthly">Plan Type</button>
            <button className="btn btn-yearly">
              {packageName === "Monthly" ? "Monthly" : "Yearly"}
            </button>

            <div className="credit-box">
              <p>
                Credits: <span>{amount && amount.credit}</span>
              </p>
              {/* <div className="line"></div>

              <p>{`Validity: ${formatDate()}`}</p> */}
            </div>
            <div className="credit-box">
              <p>
                Free credits: <span>{amount && amount.freeCredit}</span>
              </p>
              <div className="line"></div>

              <p>{`Validity: ${formatDate()}`}</p>
            </div>

            <div className="plans">
              <NavLink to="/billing" onClick={() => setPopUpUser(false)}>
                <div className="plan">
                  <DescriptionIcon className="icons" />
                  <p>Plans</p>
                </div>
              </NavLink>
              <NavLink
                to="/account/profile"
                onClick={() => setPopUpUser(false)}
              >
                <div className="plan">
                  <AccountCircleIcon className="icons" />
                  <p>Accounts</p>
                </div>
              </NavLink>
              <div className="plan">
                <SchoolIcon className="icons" />
                <p>Knowledgebase</p>
              </div>
            </div>
            <button onClick={logoutHandler} className="logout-btn">
              Logout
            </button>
          </div>
        </div>
        <div
          className="mobile-menu"
          onClick={() => setToggleMobile(!toggleMobile)}
        >
          <MenuIcon />
        </div>
        <div
          className="notification-bell"
          onClick={() => setNotification(!notification)}
        >
          <div
            className={`notification-num ${
              notificationCount.length &&
              notificationCount.length &&
              "red-notification "
            }`}
          >
            {/* {notificationCount.length && notificationCount.length} */}
          </div>
          <NotificationsIcon className="bell-icon" />
        </div>
      </div>

      <Modal
        ariaHideApp={false}
        style={{ overlay: { background: "rgba(0,0,0,0.9)" } }}
        className="notification-container-modal"
        isOpen={notification}
        onRequestClose={() => setNotification(false)}
      >
        <Notification />
      </Modal>
    </div>
  );
};

export default DashboardMainNav;
