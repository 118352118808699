import React, { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import { toast, ToastContainer } from "react-toastify";
import myAxios from "../../componends/MyAxios/MyAxios";
import { ToggleContext } from "../../contexts/ToggleProvider";
import { UserContext } from "../../contexts/UserProvider";
import "../css/Billing.css";
import AddCreditModal1 from "./AddCreditModal1";
import PackageModal from "./PackageModal";

import { AiFillCreditCard } from "react-icons/ai";
import { RiAccountBoxFill } from "react-icons/ri";
import { MdSubscriptions } from "react-icons/md";
import { FaCalendarAlt } from "react-icons/fa";
import Alert from "../../componends/Utilities/Alert";

const Billing = ({ amount }) => {
  const [planlocal, setPlanlocal] = useState("");
  const {
    activeColorf,
    setActiveColorf,
    activeColors,
    setActiveColors,
    packageModal,
    setPackageModal,
    addCreditModalUpdate,
    setAddCreditModalUpdate,
  } = useContext(ToggleContext);
  const {
    emailCredit,
    packageName,
    getUser,
    userData,
    plan,
    token,
    getNotificationCount,
  } = useContext(UserContext);

  useEffect(() => {
    document.title = `Billing | Free Email Verifier`;
    getUser();
  }, [plan]);

  const handleCancel = () => {
    myAxios
      .get(`/api/subscribe/cancel/${localStorage.getItem("vmid")}`)
      .then(({ data }) => {
        // toast(data);
        getUser();
      })
      .catch(({ response }) => toast.error(response.data));
  };

  return (
    <div className="billing-container">
      <div className="container">
        <ToastContainer />

        <div
          className="billing-section-addCredit-btn"
          onClick={() => {
            setAddCreditModalUpdate(true);
          }}
        >
          <a href="#" className="btn blue">
            Add Credit $
          </a>
        </div>

        <section className="current-status current-status-billing">
          <div className="current-status-row">
            <div className="status-card">
              <div className="status-logo">
                <AiFillCreditCard className="logo" />
              </div>
              <div className="status-text">
                <h4>{(amount && amount.credit) || 0}</h4>
                <p>Account Credits</p>
              </div>
            </div>
            <div className="status-card">
              <div className="status-logo">
                <AiFillCreditCard className="logo" />
              </div>
              <div className="status-text">
                <h4>{(amount && amount.freeCredit) || 0}</h4>
                <p>Free Credits</p>
              </div>
            </div>
            <div className="status-card">
              <div className="status-logo">
                <RiAccountBoxFill className="logo" />
              </div>
              <div className="status-text">
                <h4>Prepaid</h4>
                <p>Account Type</p>
              </div>
            </div>
          </div>
        </section>

        <Modal
          ariaHideApp={false}
          className="modal-class-package"
          isOpen={packageModal}
          onRequestClose={() => setPackageModal(false)}
        >
          <PackageModal
            getUser={getUser}
            planlocal={planlocal}
            userData={userData}
            success={(data) => {
              toast(data);
              setPackageModal(false);
            }}
            refuse={(data) => {
              toast.error(data);
              setPackageModal(false);
            }}
            packageModal={packageModal}
            setPackageModal={setPackageModal}
            getNotificationCount={getNotificationCount}
          />
        </Modal>

        <Modal
          ariaHideApp={false}
          className="modal-class-package"
          isOpen={addCreditModalUpdate}
          onRequestClose={() => setAddCreditModalUpdate(false)}
        >
          <AddCreditModal1
            plan={plan}
            userData={userData}
            addCreditModalUpdate={addCreditModalUpdate}
            setAddCreditModalUpdate={setAddCreditModalUpdate}
          />
        </Modal>
      </div>
    </div>
  );
};

export default Billing;
