import React from "react";
import "../css/Contact.css";
const Contact = () => {
  return (
    <div className="contact-container">
      <div className="contact-title">
        <h1>Contact Us</h1>
      </div>
      <div className="contact-form-container">
        <div className="contact-form-content">
          <p>
            If you have any questions or concerns, please fill in the form below
            and we will get back to you shortly.
          </p>
          <p>Address</p>
          <p>99 Wall Street #112, New York NY 10005</p>
          <p>
            Email Us{" => "}
            <a href="mailto:support@free-emailverifier.com">
              support@free-emailverifier.com
            </a>
          </p>
        </div>
        {/* <form>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              placeholder="Name"
              className="form-control"
              id="name"
              aria-describedby="name"
            />
          </div>
          <div className="form-group">
            <label htmlFor="name">Subject</label>
            <input
              type="text"
              placeholder="Subject"
              className="form-control"
              id="mobile"
              aria-describedby="mobile"
            />
          </div>
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Email address</label>
            <input
              type="text"
              placeholder="Your Email"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
          <div className="form-group">
            <label htmlFor="exampleFormControlTextarea1">Message</label>
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              rows="5"
              placeholder="Enter message"
            ></textarea>
          </div>
          <button type="submit" className="contact-btn">
            Send Message
          </button>
        </form> */}
      </div>
    </div>
  );
};

export default Contact;
