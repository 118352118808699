import CloseIcon from "@material-ui/icons/Close";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PublicIcon from "@material-ui/icons/Public";
import React, { useContext, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import {
  validateDomain,
  validateEmail,
} from "../../componends/Utilities/Helper";
import { ToggleContext } from "../../contexts/ToggleProvider";
import "../css/DomainHead.css";
import "../css/NewSendingDomainModal.css";
import myAxios from "../../componends/MyAxios/MyAxios";

const NewSendingDomainModal = () => {
  const { setSendingDomainModal } = useContext(ToggleContext);
  const [emailSelected, setEmailSelected] = useState(false);
  const [domainSelected, setDomainSelected] = useState(true);
  const [domainName, setDomainName] = useState("");
  const [emailName, setEmailName] = useState("");
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (domainSelected) {
      if (!validateDomain(domainName)) {
        toast.error("Domain Format is not valid");
        return;
      }

      setLoading(true);

      try {
        const { data } = await myAxios.post(
          `/api/domain/add/${localStorage.getItem("vmid")}`,
          {
            domain: domainName,
          }
        );
        toast(data.join(","));
        setDomainName("");
        setLoading(false);
      } catch (error) {
        toast.error(error.response.data.join(","));
        setLoading(false);
      }
    } else {
      if (!validateEmail(emailName)) {
        toast.error("Email Format is not valid");
        return;
      }
      setLoading(true);

      try {
        const { data } = await myAxios.post(
          `/api/domain/add/${localStorage.getItem("vmid")}`,
          {
            domain: emailName,
          }
        );
        toast(data.join(","));
        setEmailName("");
        setLoading(false);
      } catch (error) {
        toast.error(error.response.data.join(","));
        setLoading(false);
      }
    }
    window.location.reload();
  };
  return (
    <form className="sending-domain-modal" onSubmit={handleSubmit}>
      <ToastContainer />
      <CloseIcon
        className="sending-domain-close-icon"
        onClick={() => setSendingDomainModal(false)}
      />
      <div className="domain-head">
        <h2>Sending Identity</h2>
        <div className="domain-menuhead">
          <div
            // className={`domain-menuhead-item ${emailSelected && "active"}`}
            className={`domain-menuhead-item`}
            onClick={() => {
              setEmailSelected(false);
              setDomainSelected(true);
            }}
          >
            <MailOutlineIcon className="domain-menuhead-item-icon" />
            <p>Email Address</p>
          </div>
          <div
            className={`domain-menuhead-item ${domainSelected && "active"}`}
            onClick={() => {
              setDomainSelected(true);
              setEmailSelected(false);
            }}
          >
            <PublicIcon className="domain-menuhead-item-icon" />
            <p>Domains</p>
          </div>
        </div>
        <div className="horizontal-line"></div>
      </div>
      <div className="sending-domain-body">
        <h2>{domainSelected ? "Domain" : "Email"}</h2>
        <p>
          {domainSelected
            ? "Sending domain is used to verify the sender whose email address appearing in the FROM header of an email. Add your own verified sending domains to send emails on your or your organization's behalf."
            : "Sending Email is used to verify the sender whose email address appearing in the FROM header of an email. Add your own verified sending email to send emails on your or your organization's behalf."}
        </p>
        {domainSelected ? (
          <input
            type="text"
            placeholder="ENTER DOMAIN NAME"
            value={domainName}
            onChange={(e) => setDomainName(e.target.value)}
          />
        ) : (
          <input
            type="text"
            placeholder="ENTER EMAIL NAME"
            value={emailName}
            onChange={(e) => setEmailName(e.target.value)}
          />
        )}

        <button>
          {loading ? "Loading..." : domainSelected ? "ADD DOMAIN" : "ADD EMAIL"}
        </button>
      </div>
    </form>
  );
};

export default NewSendingDomainModal;
