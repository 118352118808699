import React from "react";
import "./ProfileHead.css";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import PersonIcon from "@material-ui/icons/Person";
import PermPhoneMsgIcon from "@material-ui/icons/PermPhoneMsg";
import { NavLink } from "react-router-dom";

const ProfileHead = ({ firstName, lastName }) => {
  return (
    <div className="profile-head-container">
      <div className="profile-header">
        <ContactPhoneIcon className="contactbook-icon" />
        <h2>
          {firstName} {lastName}
        </h2>
      </div>
      <nav>
        <ul>
          <NavLink to="/account/profile" activeClassName="active">
            <li>
              <PersonIcon className="icons" /> My profile
            </li>
          </NavLink>
          <NavLink to="/account/contact">
            <li>
              <PermPhoneMsgIcon className="icons" /> Contact Information
            </li>
          </NavLink>
          {/* <NavLink to="/dashboard/account/subscriptions">
            <li>
              <SubscriptionsIcon className="icons" /> Subscriptions
            </li>
          </NavLink> */}
          {/* <NavLink to="/account/emails">
            <li>
              <BookIcon className="icons" /> Email Logs
            </li>
          </NavLink>
          <NavLink to="/account/api">
            <li>
              <VpnKeyIcon className="icons" /> API Token
            </li>
          </NavLink> */}
        </ul>
      </nav>
    </div>
  );
};

export default ProfileHead;
