import React, { useEffect } from "react";
import "../css/404.css";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  useEffect(() => {
    document.title = `404 | Free Email Verifier`;
  }, []);
  return (
    <div className="pagenotfound-container">
      <div className="main-title">404</div>
      <div className="secondary-title">oops! somethig nothig was found</div>
      <p>The page you are looking for might have been removed</p>
      <p>had its name changed or is temporary unavailable</p>
      <Link to="/">
        <button className="back-to-home">Back to home</button>
      </Link>
    </div>
  );
};

export default PageNotFound;
