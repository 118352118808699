import React, { useEffect, useState, useContext } from "react";
import moment from "moment";
import SearchIcon from "@material-ui/icons/Search";
import "./Emails.css";
import myAxios from "../MyAxios/MyAxios";
import { UserContext } from "../../contexts/UserProvider";
import { toast, ToastContainer } from "react-toastify";

const Emails = () => {
  const [emails, setEmails] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [limits, setLimits] = useState(10);
  const [totalCountEmail, setTotalCountEmail] = useState(0);
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [noEmail, setNoEmail] = useState("");
  const { getUser, loading, setLoading } = useContext(UserContext);

  useEffect(() => {
    document.title = `Email Log | Free Email Verifier`;
    getUser();
  }, []);

  useEffect(() => {
    setLoading(true);
    myAxios
      .post("/api/dashboardemail", {
        _id: localStorage.getItem("vmid"),
        page: currentPage,
        size: limits,
      })
      .then((response) => {
        console.log(response);
        setLoading(false);
        setEmails(response.data.data);
        setTotalCountEmail(response.data.TotalCount);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.response.data);
        setNoEmail(error.response.data);
      });
  }, [currentPage]);

  const handleClearEmail = () => {
    setLoadingEmail(true);
    myAxios
      .post(`/api/deleteemailqueue/${localStorage.getItem("vmid")}`)
      .then((response) => {
        toast.success(response.data);
        setLoadingEmail(false);
      })
      .catch((error) => {
        toast.error(error.response.data);
        setLoadingEmail(false);
      });
  };

  return (
    !loading && (
      <>
        {noEmail ? (
          <div className="no-email">
            <h1>{noEmail && noEmail}</h1>
          </div>
        ) : (
          <div className="emails-container">
            <ToastContainer />
            <div className="table-header">
              <div className="table-header-right">
                <button
                  className="clear-email-queue"
                  onClick={handleClearEmail}
                >
                  {loadingEmail ? "Loading.." : "Clear Email Queue"}
                </button>
              </div>
              <div className="table-header-right">
                <div className="search">
                  <input type="text" placeholder="Search" />
                  <SearchIcon />
                </div>
              </div>
            </div>
            <table id="customers">
              <thead>
                <tr>
                  <th>FromEmail</th>
                  <th>FromName</th>
                  <th>ToAddress</th>
                  <th>Date</th>
                  <th>Subject</th>
                  <th>Sending status</th>
                </tr>
              </thead>
              <tbody>
                {emails &&
                  emails.map((email) => (
                    <tr key={email._id}>
                      <td>{email.from.address}</td>
                      <td>{email.from.name}</td>
                      <td>{email.destaddr[0].to.address}</td>
                      <td className="date">
                        {moment(email.recieve_date).format(
                          "MMM Do YYYY, h:mm a"
                        )}
                      </td>
                      <td className="subject">{email.subject}</td>
                      <td>{email.sent === 1 ? "Success" : "Sending.."}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div className="pagination">
              <div
                className={`previous ${currentPage <= 1 ? "disabled" : null}`}
                onClick={() => {
                  if (currentPage <= 1) return;
                  setCurrentPage((currentPage) => currentPage - 1);
                }}
              >
                &laquo; Previous
              </div>
              <div
                className={`next ${
                  limits * currentPage > totalCountEmail ? "disabled" : null
                }`}
                onClick={() => {
                  if (limits * currentPage > totalCountEmail) return;
                  setCurrentPage((currentPage) => currentPage + 1);
                }}
              >
                Next &raquo;
              </div>
            </div>
          </div>
        )}
      </>
    )
  );
};

export default Emails;
