import React from "react";
import "./Footer.css";

import { GrFacebookOption } from "react-icons/gr";
import { FaTwitter } from "react-icons/fa";
import { FaGooglePlusG } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="footer-container">
      <section className="footer-row">
        <div className="footer-column text">
          &copy; {new Date().getFullYear()} Free-EmailVerifier.com. All Right
          Reserved
        </div>
        <div className="footer-column">
          <GrFacebookOption className="react-icon" />
          <FaTwitter className="react-icon" />
          <FaGooglePlusG className="react-icon" />
          <FaInstagram className="react-icon" />
        </div>
      </section>
    </div>
  );
};

export default Footer;
