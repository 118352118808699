import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./componends/Home/Home";
import myAxios from "./componends/MyAxios/MyAxios";
import ValidationEmailPage from "./componends/Home/ValidationEmailPage";
import Header from "./componends/Home/Header";
import Footer from "./componends/Home/Footer";
import Dashboard from "./componends/Dashboard/Dashboard";
import Login from "./componends/Dashboard/Login";
import Contact from "./componends/Dashboard/Contact";
import Privacy from "./componends/Dashboard/Privacy";
import PageNotFound from "./componends/Dashboard/404";
import ResisterCustom from "./componends/Dashboard/ResisterCustom";
import PasswordForgot from "./componends/Dashboard/PasswordForgot";
import ResetPassword from "./componends/Dashboard/ResetPassword";
import EmailVerify from "./componends/Dashboard/EmailVerify";
import UserUnsubscribed from "./componends/Dashboard/UserUnsubscribed";
import UserActivate from "./componends/Dashboard/UserActivate";
import { ProtectedRoute } from "./componends/ProtectedRoute/ProtectedRoute";

//modals
import SignupModal from "./componends/EmailsProject/SignupModal";
import ThankuModal from "./componends/EmailsProject/ThankuModal";
// import AddCreditModal1 from "./componends/Dashboard/AddCreditModal1";

const App = () => {
  const [isSignup, setIsSignup] = useState(false);
  const [countVerify, setCountVerify] = useState(0);
  const [amount, setAmount] = useState(null);
  useEffect(() => {
    if (!localStorage.getItem("countVerify")) {
      localStorage.setItem("countVerify", countVerify);
    }
  }, []);
  useEffect(() => {
    myAxios
      .get(`/api/userprofile/${localStorage.getItem("vmid")}`)
      .then((response) => {
        console.log(response.data);
        let balanceAmount = {
          credit: response.data.balance,
          freeCredit: response.data.free_balance,
          email: response.data.email,
        };
        setAmount(balanceAmount);
      })
      .catch((error) => {
        console.log(error.response);
      });
  }, []);
  return (
    <React.Fragment>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/validemail">
          <Header />
          <ValidationEmailPage />
          <Footer />
        </Route>
        <Route exact path="/contact">
          <Header />
          <Contact />
          <Footer />
        </Route>
        <Route exact path="/privacy">
          <Header />
          <Privacy />
          <Footer />
        </Route>
        <Route exact path="/login">
          <Login />
        </Route>
        <Route exact path="/forgot">
          <PasswordForgot />
        </Route>

        <Route exact path="/reset/:id">
          <ResetPassword />
        </Route>
        <Route exact path="/emailverify/:id">
          <EmailVerify />
        </Route>
        <Route exact path="/user/unsubscribe/:id">
          <UserUnsubscribed />
        </Route>
        <Route exact path="/user/activate/:id">
          <UserActivate />
        </Route>
        <Route exact path="/signup/free">
          <ResisterCustom />
        </Route>
        <ProtectedRoute>
          <Dashboard amount={amount} />
        </ProtectedRoute>
        <Route path="*">
          <PageNotFound />
        </Route>
      </Switch>
      <SignupModal />
      <ThankuModal />
    </React.Fragment>
  );
};

export default App;
