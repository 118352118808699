import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { FaLock } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import myAxios from "../MyAxios/MyAxios";

const AddCreditModal1 = ({ setAddCreditModalUpdate }) => {
  const history = useHistory();
  let user_id = localStorage.getItem("vmid");
  let email = localStorage.getItem("vmuser");
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expMonth, setExpMonth] = useState("");
  const [expYear, setExpYear] = useState("");
  const [cvc, setCVC] = useState("");
  const [amount, setAmount] = useState("");
  const [couponToggle, setCouponToggle] = useState(false);
  const [cuponValidate, setCuponValidate] = useState("");
  const [coupon, setCoupon] = useState("");
  const [cuponLoading, setCouponLoading] = useState("");
  const [required, setRequired] = useState(false);
  const [paymentBudget, setPaymentBudget] = useState("");

  const CuponHandler = () => {
    console.log("coupon handler");
    setCouponLoading("loading");
    if (coupon === "") {
      toast.error("Please enter coupon code");
      setCouponLoading("");
      return;
    }
    console.log("coupon", coupon);
    setCouponLoading("");
    // myAxios
    //   .post("/validate-coupon", {
    //     couponName: coupon,
    //     userid: user_id,
    //   })
    //   .then((res) => {
    //     let cuponPercentage = parseInt(res.data.percentage) / 100;
    //     let couponPercentageBudget = Math.ceil(
    //       amount - amount * cuponPercentage
    //     );
    //     setPaymentBudget(couponPercentageBudget);
    //     toast.success("Coupon validate");
    //     setCuponValidate("validate");
    //     setCouponLoading("");
    //     setCouponToggle(false);
    //   })
    //   .catch((err) => {
    //     toast.error(err.response && err.response.data && err.response.data);
    //     if (err.response.status === 401) {
    //       toast.error(err.response.data.message);
    //     }
    //     setCouponLoading("");
    //     setCouponToggle(false);
    //     setCuponValidate("");
    //     setCoupon("");
    //     return;
    //   });
  };
  const handlePayment = (e) => {
    e.preventDefault();

    if (
      name === "" ||
      cardNumber === "" ||
      expMonth === "" ||
      expYear === "" ||
      cvc === "" ||
      amount === ""
    ) {
      setRequired(true);
      toast.error("Please fill all the fields");
      return;
    }

    if (amount < 10) {
      return toast.error("Minimum amount is $10");
    }
    setLoading(true);

    const paymentInfo = {
      user_id,
      email,
      name,
      cardNumber,
      expMonth,
      expYear,
      cvc,
      budget: amount,
    };

    myAxios
      .post("/api/payment", paymentInfo)
      .then((response) => {
        console.log("payment response", response.data);
        toast.success("Payment Success");
        setLoading(false);
        setAddCreditModalUpdate(false);
        history.push("/thanks");
      })
      .catch((error) => {
        if (error.response.status === 401) {
          toast.error(error.response.data.message);
        }
        if (error.response.data?.raw?.message) {
          toast.error(error.response.data.raw.message);
        } else {
          toast.error(error.response.data);
        }
        setLoading(false);

        return;
      });
  };

  return (
    <div className="payment-modal-container-inner">
      <ToastContainer />
      <div className="modal-content">
        <div className="modal-header">
          <button
            type="button"
            className="close"
            onClick={() => setAddCreditModalUpdate(false)}
          >
            &times;
          </button>
          <p className="payment-top text-center">
            Secure Transaction by 256-bit industry leading SSL encryption. Don’t
            worry, you are safe here
          </p>
          <div id="payment-errors"></div>
        </div>

        <div className="modal-body">
          <div className="modal-body-title">
            <div className="modal-body-title-left">
              <h4>
                Enter <span>credit card</span> below.
              </h4>
            </div>
            <div className="modal-body-title-right">
              {amount && `📧${amount / 0.002}`}
            </div>
          </div>

          <form className="paymentFrm">
            <div className="form-group">
              <label>Amount</label>
              <input
                type="number"
                placeholder="Enter Amount"
                className={
                  amount === "" && required
                    ? "form-control red-error-border"
                    : "form-control"
                }
                name="phone_number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Name</label>
              <input
                type="text"
                placeholder="Name"
                className={
                  name === "" && required
                    ? "form-control red-error-border"
                    : "form-control"
                }
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <input
                type="hidden"
                name="email"
                className={
                  required ? "form-control red-error-border" : "form-control"
                }
                placeholder="email@you.com"
              />
            </div>
            <div className="form-group">
              <label>Card Number</label>
              <input
                required="true"
                type="number"
                placeholder="Card Number"
                className={
                  cardNumber === "" && required
                    ? "form-control red-error-border"
                    : "form-control"
                }
                name="card_num"
                id="card_num"
                autoComplete="off"
                required="required"
                value={cardNumber}
                onChange={(e) => setCardNumber(e.target.value)}
              />
            </div>
            <div className="form-group form-group-container">
              <div className="col-xs-4">
                <label>Month</label>
                <input
                  type="number"
                  className={
                    expMonth === "" && required
                      ? "form-control red-error-border"
                      : "form-control"
                  }
                  name="exp_month"
                  placeholder="MM"
                  id="card-expiry-month"
                  value={expMonth}
                  onChange={(e) => setExpMonth(e.target.value)}
                />
              </div>
              <div className="col-xs-4">
                <label>Year</label>
                <input
                  required
                  type="number"
                  className={
                    expYear === "" && required
                      ? "form-control red-error-border"
                      : "form-control"
                  }
                  name="exp_year"
                  id="card-expiry-year"
                  placeholder="YYYY"
                  value={expYear}
                  onChange={(e) => {
                    setExpYear(e.target.value);
                  }}
                />
              </div>
              <div className="col-xs-4">
                <label>CVC</label>
                <input
                  required
                  type="number"
                  name="cvc"
                  id="card-cvc"
                  maxLength="3"
                  className={
                    cvc === "" && required
                      ? "form-control red-error-border"
                      : "form-control"
                  }
                  autoComplete="off"
                  placeholder="CVC"
                  value={cvc}
                  onChange={(e) => setCVC(e.target.value)}
                />
              </div>
            </div>

            {/* adding coupon code */}
            {/* <div className="form-group">
              <label>
                <>
                  Have a coupon ?{" "}
                  <span
                    className="cupon-click"
                    onClick={() => setCouponToggle(!couponToggle)}
                  >
                    Click Here
                  </span>
                </>
              </label>

              {couponToggle ? (
                <div className="cupon-validate-container">
                  <div className="cupon-validate-content">
                    <label></label>
                    <input
                      type="text"
                      className="form-control"
                      name="cupon"
                      placeholder="Coupon Code"
                      value={coupon}
                      onChange={(e) => {
                        setCoupon(e.target.value);
                      }}
                    />
                  </div>
                  <div className="cupon-validate-button" onClick={CuponHandler}>
                    {cuponLoading === "loading" ? "Loading.." : "Validate"}
                  </div>
                </div>
              ) : null}
            </div> */}

            {/* end */}

            <div className="form-group">
              <label className="promo"></label>
            </div>
            <div className="form-group">
              <button
                type="submit"
                className={
                  loading
                    ? "payment-success-button disabled"
                    : "payment-success-button"
                }
                onClick={handlePayment}
              >
                {loading ? "Loading.." : "Pay Now!"}
              </button>
            </div>
          </form>
        </div>
        <div className="payment-footer">
          <div className="payment">
            <FaLock className="lock-icon" />{" "}
            <h5>
              100% Money Back Guarantee <span></span>
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCreditModal1;
