import React, { useContext, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import AdSense from "react-adsense";
import { EmailContext } from "../../contexts/EmailProvider";
import "./ValidationEmailPage.css";
import { useEffect } from "react";

//icons
import { FcApproval } from "react-icons/fc";
import { AiOutlineCloseCircle } from "react-icons/ai";

const ValidationEmailPage = () => {
  const { email } = useContext(EmailContext);
  const location = useLocation();
  const history = useHistory();
  const [isValid, setIsValid] = useState(false);
  const [acceptMail, setAcceptMail] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { state } = location;
  console.log("state", state);

  useEffect(() => {
    setIsValid(state.data.format);
    setAcceptMail(state.data.accept_all);
    setValidEmail(state.data.is_verified);
    setErrorMessage(state.data.errors);
  });
  return (
    <div className="validation-page-container">
      <div className="validation-page-ads-container">
        {/* <div className="validation-page-adds">
          <AdSense.Google
            client="ca-pub-1782749546891141"
            slot="5351171120"
            style={{ display: "block" }}
            format="auto"
            responsive="true"
            layoutKey="-gw-1+2a-9x+5c"
          />
        </div> */}
      </div>
      <div className="validation-page-info-container">
        <div className="validation-page-info-title">
          Your email validation results for {email} will be displayed below
          Thank you.
        </div>
        {errorMessage && (
          <div className="error-message-validateion">{errorMessage}</div>
        )}

        <div className="validation-page-info-content">
          <div>
            {isValid ? (
              <FcApproval style={{ marginRight: "5px" }} className="icon" />
            ) : (
              <AiOutlineCloseCircle
                className="icon"
                style={{ color: "red", marginRight: "5px" }}
              />
            )}
            <p className={isValid ? "valid" : "invalid"}>
              {isValid
                ? "Email Address Syntax is Valid"
                : "Email Address Syntax is Not Valid"}
            </p>
          </div>
          <div>
            {acceptMail ? (
              <FcApproval style={{ marginRight: "5px" }} className="icon" />
            ) : (
              <AiOutlineCloseCircle
                className="icon"
                style={{ color: "red", marginRight: "5px" }}
              />
            )}
            <p className={acceptMail ? "valid" : "invalid"}>
              {acceptMail ? "Accept Email is YES" : "Accept Email is NO"}
            </p>
          </div>
          <div>
            {acceptMail ? (
              <FcApproval style={{ marginRight: "5px" }} className="icon" />
            ) : (
              <AiOutlineCloseCircle
                className="icon"
                style={{ color: "red", marginRight: "5px" }}
              />
            )}
            <p className={validEmail ? "valid" : "invalid"}>
              {validEmail
                ? `${email} seems to be Valid`
                : `${email} seems to be Invalid`}
            </p>
          </div>

          {!errorMessage && (
            <div>
              <FcApproval style={{ marginRight: "5px" }} className="icon" />
              <p className="valid">
                {`Email Validation Accuracy  ${
                  email.includes("@aol") || email.includes("@yahoo")
                    ? "80%"
                    : "100%"
                }`}
              </p>
            </div>
          )}
        </div>
        <div className="validation-page-info-footer">
          <h4>Would you like to test another email ? Click the button below</h4>
          <button onClick={() => history.push("/")}>TEST ANOTHER EMAIL</button>
        </div>
      </div>
      {/* <div className="validation-page-adds">
        <AdSense.Google
          client="ca-pub-1782749546891141"
          slot="5351171120"
          style={{ display: "block" }}
          format="auto"
          responsive="true"
          layoutKey="-gw-1+2a-9x+5c"
        />
      </div> */}
    </div>
  );
};

export default ValidationEmailPage;
