import React, { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import sortBy from "lodash/sortBy";
import DomainHead from "./DomainHead";
import DomainTable from "./DomainTable";
import NewSendingDomainModal from "./NewSendingDomainModal";
import "../css/Domains.css";

//contexts
import { ToggleContext } from "../../contexts/ToggleProvider";
import { UserContext } from "../../contexts/UserProvider";

//icons

import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";

const SendingDomain = () => {
  const [sortType, setSortType] = useState("name");
  const { sendingDomainModal, setSendingDomainModal } =
    useContext(ToggleContext);
  const { getDomainList, domainList, getUser } = useContext(UserContext);

  const handleSort = (e) => {
    setSortType(e.target.value);
  };

  useEffect(() => {
    document.title = `sending_domain | QUEENSMTP`;
    getUser();
    getDomainList();
  }, []);

  return (
    <div className="domain-container">
      <DomainHead title="Domains" />

      <div className="domain-table-head">
        <div className="domain-table-head-left">
          {/* <div className="domain-table-head-left-one">
            <input type="checkbox" className="table-head-checkbox" />
            <p>Sort By </p>
            <select className="table-head-select" onChange={handleSort}>
              <option value="name">Name</option>
              <option value="created">Created at</option>
              <option value="updated">Updated at</option>
            </select>
          </div> */}
          {/* <div className="domain-table-head-left-two">
            <div className="domain-table-head-inputbox">
              <input
                type="text"
                placeholder="Type to search"
                className="domain-table-head-input"
              />
              <SearchIcon className="search-icon" />
            </div>
          </div> */}
        </div>
        <div className="domain-table-head-right">
          <div
            className="domain-sending-button"
            onClick={() => setSendingDomainModal(!sendingDomainModal)}
          >
            <AddIcon className="add-icon" />
            Add Sending Domain
          </div>
          <Modal
            className="sending-domain-modal-container"
            isOpen={sendingDomainModal}
            onRequestClose={() => setSendingDomainModal(false)}
          >
            <NewSendingDomainModal />
          </Modal>
        </div>
      </div>

      {domainList &&
        domainList.map((domain) => (
          <DomainTable
            key={domain._id}
            id={domain._id}
            name={domain.name}
            email_verified={domain.domain_verified}
            dkim_verified={domain.dkim_verified}
            spf_verified={domain.spf_verified}
            createdAt={domain.createdAt || ""}
            getDomainList={getDomainList}
          />
        ))}
    </div>
  );
};

export default SendingDomain;
