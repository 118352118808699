import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import CloseIcon from "@material-ui/icons/Close";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import { RiExchangeFundsLine } from "react-icons/ri";

function ChangePassModal({ setPassModal }) {
  const [password, setPassword] = useState("");
  const [npassword, setNPassword] = useState("");
  const [cpassword, setCpassword] = useState("");
  const [loading, setLoading] = useState(false);
  const changePassword = (e) => {
    e.preventDefault();
    if (npassword === cpassword) {
      return toast.error("Password does not match");
    }
    console.log(password, npassword, cpassword);
  };
  return (
    <div>
      <ToastContainer />
      <div className="modal-close" onClick={() => setPassModal(false)}>
        <CloseIcon />
      </div>
      <form onSubmit={changePassword}>
        <div className="input-group">
          <label>
            Old Password <span>*</span>
          </label>
          <input
            // required
            className="password"
            type="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="input-group">
          <label>
            New Password <span>*</span>
          </label>
          <input
            // required
            className="password"
            type="password"
            name="npassword"
            value={npassword}
            onChange={(e) => setNPassword(e.target.value)}
          />
        </div>
        <div className="input-group">
          <label>
            Confirm new password <span>*</span>
          </label>
          <input
            // required
            className="password"
            type="password"
            name="cpassword"
            value={cpassword}
            onChange={(e) => setCpassword(e.target.value)}
          />
        </div>
        <button
          type="submit"
          className="form-button"
          style={{ marginTop: "10px" }}
        >
          <RiExchangeFundsLine
            style={{ fontSize: "18px", fontWeight: "bold", marginRight: "5px" }}
          />{" "}
          {loading ? "Loading" : "Change Password"}
        </button>
      </form>
    </div>
  );
}

export default ChangePassModal;
