import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Header.css";

import LogoImage from "../../images/emailverifier.png";

import { FiMenu } from "react-icons/fi";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        document.querySelector("header").style.paddingTop = ".5rem";
        document.querySelector("header").style.paddingBottom = ".5rem";
      } else {
        document.querySelector("header").style.paddingTop = "1rem";
        document.querySelector("header").style.paddingBottom = "1rem";
      }
    });
  }, []);
  return (
    <header>
      <div
        className="logo"
        onClick={() => {
          window.location.href = "/";
        }}
      >
        <img src={LogoImage} alt="logo" style={{ cursor: "pointer" }} />
      </div>
      <div id="menu-bar" onClick={() => setIsOpen(!isOpen)}>
        <FiMenu />
      </div>
      <nav className={`navbar ${isOpen ? "active" : null}`}>
        {localStorage.getItem("isSignup") === "true" &&
        localStorage.getItem("vmid") ? (
          <>
            <Link to="/dashboard">Dashboard</Link>
            <Link to="/contact">Contact</Link>
          </>
        ) : (
          <>
            <a href="https://queensmtp.com" target="blank">
              SMTP Service
            </a>
            <Link to="/signup/free">SignUp</Link>
            <Link to="/login">Login</Link>
            <Link to="/contact">Contact</Link>
          </>
        )}
      </nav>
    </header>
  );
};

export default Header;
