import React from "react";
import { useHistory } from "react-router-dom";
import "./VerifySection.css";

const VerifySection = () => {
  const history = useHistory();
  return (
    <div className="verify-section">
      <div className="verify-section-container">
        <div className="verify-section-content">
          <div className="verify-section-content-title">
            VERIFY BULK EMAIL ADDRESS
          </div>
          <div className="verify-section-content-text">
            Email checker tool will clean your mailing list and increase deliver
            ability rate up to 99%. The email address validation process was
            never so easy.
          </div>
          <button
            onClick={() => {
              history.push("/account/bulk-verifier");
            }}
          >
            SIGN UP NOW!
          </button>
        </div>
      </div>
    </div>
  );
};

export default VerifySection;
