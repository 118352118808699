import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import SearchIcon from "@material-ui/icons/Search";
import Modal from "react-modal";

import "./CreateProject.css";
import CreateProjectModal from "./CreateProjectModal";
import UploadCsvModal from "./UploadCsvModal";
import UploadEmail from "./UploadEmail";
import myAxios from "../MyAxios/MyAxios";

const CreateProject = () => {
  const history = useHistory();
  const [createProjectModal, setCreateProjectModal] = useState(false);
  const [uploadCsvModal, setUploadCsvModal] = useState(false);
  const [uploadEmail, setUploadEmail] = useState(false);
  const [projects, setProjects] = useState([]);
  const getProjects = useCallback(() => {
    myAxios
      .get(`/api/get-projects/${localStorage.getItem("vmid")}`)
      .then((res) => {
        console.log(res.data);
        setProjects(res.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, [setProjects]);
  useEffect(() => {
    document.title = `Create Project | Free Email Verifier`;
    getProjects();
  }, []);
  return (
    <div className="emails-container">
      <ToastContainer />
      <div className="table-header">
        <div className="table-header-right">
          <button
            className="email-create-btn"
            onClick={() => setCreateProjectModal(!createProjectModal)}
          >
            Create Project
          </button>
        </div>
        <div className="table-header-right">
          <div className="search">
            <input type="text" placeholder="Search" />
            <SearchIcon />
          </div>
        </div>
      </div>
      <table id="customers" className="email-project-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Upload Email</th>
            <th>Add Email</th>
            <th>Auto Verify</th>
            <th>Email Number</th>
            <th>Project Status</th>
            <th>Details</th>
          </tr>
        </thead>
        <tbody>
          {projects.map((project) => {
            return (
              <tr key={project._id}>
                <td>{project.project_name}</td>
                <td>
                  <button
                    className="email-create-btn"
                    onClick={() => setUploadCsvModal(!uploadCsvModal)}
                  >
                    Upload
                  </button>
                </td>
                <td>
                  <button
                    className="email-create-btn"
                    onClick={() => setUploadEmail(!uploadEmail)}
                  >
                    Add Email
                  </button>
                </td>
                <td>{project.auto_verify} Time Week</td>
                <td>{project.emails.length}</td>
                <td>Working</td>
                <td>
                  <button
                    className="email-create-btn"
                    onClick={() => history.push("/create-project/details")}
                  >
                    Details
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* <div className="pagination">
        <div
          className={`previous ${currentPage <= 1 ? "disabled" : null}`}
          onClick={() => {
            if (currentPage <= 1) return;
            setCurrentPage((currentPage) => currentPage - 1);
          }}
        >
          &laquo; Previous
        </div>
        <div
          className={`next ${
            limits * currentPage > totalCountEmail ? "disabled" : null
          }`}
          onClick={() => {
            if (limits * currentPage > totalCountEmail) return;
            setCurrentPage((currentPage) => currentPage + 1);
          }}
        >
          Next &raquo;
        </div>
      </div> */}

      <Modal
        ariaHideApp={false}
        style={{ overlay: { background: "rgba(0,0,0,0.9)" } }}
        className="modal-class-project-name"
        isOpen={createProjectModal}
        onRequestClose={() => setCreateProjectModal(false)}
      >
        <CreateProjectModal setCreateProjectModal={setCreateProjectModal} />
      </Modal>
      <Modal
        ariaHideApp={false}
        style={{ overlay: { background: "rgba(0,0,0,0.9)" } }}
        className="modal-class-upload-email"
        isOpen={uploadCsvModal}
        onRequestClose={() => setUploadCsvModal(false)}
      >
        <UploadCsvModal setUploadCsvModal={setUploadCsvModal} />
      </Modal>
      <Modal
        ariaHideApp={false}
        style={{ overlay: { background: "rgba(0,0,0,0.9)" } }}
        className="modal-class-add-email"
        isOpen={uploadEmail}
        onRequestClose={() => setUploadEmail(false)}
      >
        <UploadEmail setUploadEmail={setUploadEmail} />
      </Modal>
    </div>
  );
};

export default CreateProject;
