import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import ReactLoading from "react-loading";
import myAxios from "../MyAxios/MyAxios";
import { toast, ToastContainer } from "react-toastify";
import LogoImage from "../../images/emailverifier.png";
import "../css/Login.css";

const EmailVerify = () => {
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    document.title = `Verifying Email Address| Free Email Verifier`;
    myAxios
      .post(`/api/email-verify/${id}`)
      .then((response) => {
        localStorage.setItem("isSignup", true);
        localStorage.setItem("signupemailsend", 1);
        history.push("/");
      })
      .catch((error) => {
        toast.error(error.response.data);
        history.push("/");
      });
  }, []);

  return (
    <div className="login-main-container">
      <ToastContainer />
      <form className="login-container">
        <img src={LogoImage} alt="stripe-image" className="login-logo" />

        <div
          className="login-card"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ReactLoading
            type={"spin"}
            color={"#8834fd"}
            height={150}
            width={150}
            delay={20}
          />
        </div>
      </form>
    </div>
  );
};

export default EmailVerify;
