import React, { useState, useEffect, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import { LoginContext } from "../../contexts/LogingProvider";
import { toast, ToastContainer } from "react-toastify";
import myAxiosGlobal from "../MyAxios/MyAxiosGlobal";
import LogoImage from "../../images/queensmtpfinal.png";
import "../css/Login.css";

const ResetPassword = () => {
  const { id } = useParams();

  const [newpassword, setNewPassword] = useState("");
  const [retypepassword, setRetypePassword] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = `Reset Password | Free Email Verifier`;
  }, []);

  const handleResetPassword = (e) => {
    e.preventDefault();
    setLoading(true);
    if (newpassword !== retypepassword) {
      setLoading(false);
      return toast.error("Passwords do not match");
    }
    myAxiosGlobal
      .post(`resetpasswordclick/${id}`, {
        password: newpassword,
      })
      .then((response) => {
        toast.success(response.data);
        setLoading(false);
        setNewPassword("");
        setRetypePassword("");
      })
      .catch((error) => {
        toast.error(error.response.data);
        setLoading(false);
      });
  };

  return (
    <div className="login-main-container">
      <ToastContainer />
      <form className="login-container" onSubmit={handleResetPassword}>
        <img src={LogoImage} alt="stripe-image" className="login-logo" />

        <div className="login-card">
          <input
            type="password"
            placeholder="New Password"
            value={newpassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <input
            type="password"
            placeholder="Re-type New Password"
            value={retypepassword}
            onChange={(e) => setRetypePassword(e.target.value)}
          />
          <button className="login-btn">
            {loading ? "Loding..." : "RESET PASSWORD"}
          </button>
          <p className="forgot-text">
            <Link to="/">Account Login</Link>
          </p>
        </div>
      </form>
    </div>
  );
};

export default ResetPassword;
