import React, { useEffect, useContext } from "react";
import "../css/Notification.css";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import { ToggleContext } from "../../contexts/ToggleProvider";
import DashboardNotification from "./DashboardNotification";
import { UserContext } from "../../contexts/UserProvider";

const Notification = () => {
  const {
    notification,
    setNotification,
    notificationSearch,
    setNotificationSearch,
  } = useContext(ToggleContext);

  const { userData, domainList, plan, token, getNotificationCount } =
    useContext(UserContext);

  return (
    <div className="notification-container">
      <div className="notification-header">
        <div className="notification-header-left">Notifications</div>
        <div className="notification-header-right">
          <SearchIcon
            className="fa-search"
            onClick={() => setNotificationSearch(!notificationSearch)}
          />
          <CloseIcon
            className="fa-times"
            onClick={() => setNotification(!notification)}
          />
        </div>
      </div>
      <div
        className={
          notificationSearch
            ? "notification-input active"
            : "notification-input"
        }
      >
        <input type="text" placeholder="Search" />
        <div className="fa-search">
          <SearchIcon className="notification-search-icon" />
        </div>
      </div>
      <div className="notification-body">
        {/* <div className="notification-body-header">
          <button>
            <i className="fas fa-bell"></i>
          </button>
        </div> */}

        <div>
          <DashboardNotification
            userData={userData}
            domainList={domainList}
            plan={plan}
            token={token}
            setNotification={setNotification}
          />
        </div>
      </div>
    </div>
  );
};

export default Notification;
