import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import "../css/ResisterCustom.css";
import { toast, ToastContainer } from "react-toastify";
import LogoImage from "../../images/emailverifier.png";
import StripeImage from "../../images/stripe.png";
import myAxiosGlobal from "../MyAxios/MyAxiosGlobal";

const Resister = () => {
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    mobile: "",
    website: "",
    country: "",
    address: "",
    city: "",
    state: "",
    postal: "",
    resistertype: "",
    updateviaemail: true,
  });

  const {
    firstname,
    lastname,
    email,
    password,
    country,
    address,
    city,
    state,
    postal,
    updateviaemail,
  } = formData;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      firstname === "" ||
      lastname === "" ||
      email === "" ||
      password === "" ||
      country === "" ||
      address === "" ||
      city === "" ||
      state === "" ||
      postal === ""
    ) {
      return toast.error("Please fill all the fields");
    }
    setLoading(true);
    myAxiosGlobal
      .post("/api/signup", formData)
      .then((response) => {
        console.log("response from signup", response);
        localStorage.setItem("vmtoken", response.data.refreshToken);
        localStorage.setItem("vmuser", response.data.user.email);
        localStorage.setItem("vmid", response.data.user._id);
        setLoading(false);
        toast("Registration success");
        setFormData({
          firstname: "",
          lastname: "",
          email: "",
          password: "",
          country: "",
          address: "",
          city: "",
          state: "",
          postal: "",
          updateviaemail: false,
        });
        window.location.href = "/dashboard";
      })
      .catch((error) => {
        toast.error(error?.response?.data);
        toast.error(error?.response?.data?.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    document.title = `Register | Free Email Verifier`;
  }, []);
  const history = useHistory();
  const homepage = () => history.push("/");
  return (
    <div className="resister-main-container">
      <div className="resister-package-box">
        <ToastContainer />

        <div className="resister-package-img">
          <img onClick={homepage} src={LogoImage} alt="logo-img" />
        </div>

        <div className="resister-stripe-image">
          <img src={StripeImage} alt="stripe-image" className="payment-logo" />
        </div>
      </div>

      <div className="resister-account-box">
        <h4 className="resiter-package-title">CREATE YOUR ACCOUNT</h4>
        <form onSubmit={handleSubmit}>
          <div className="resister-input-group">
            <input
              required
              type="text"
              name="firstname"
              className="resister-input"
              placeholder="First Name"
              id="first-name"
              value={firstname}
              onChange={handleChange}
            />
            <input
              required
              type="text"
              name="lastname"
              className="resister-input"
              placeholder="Last Name"
              id="last-name"
              value={lastname}
              onChange={handleChange}
            />
          </div>
          <div className="resister-input-group">
            <input
              required
              type="email"
              name="email"
              className="resister-input"
              placeholder="Email Address"
              id="email"
              value={email}
              onChange={handleChange}
            />
            <input
              required
              type="password"
              name="password"
              className="resister-input"
              placeholder="Password"
              id="cofirm-password"
              value={password}
              onChange={handleChange}
            />
          </div>

          <p className="country-label">Country of Residence</p>
          <select
            required
            className="resister-input"
            name="country"
            value={country}
            onChange={handleChange}
          >
            <option value=""></option>
            <option value="Afghanistan">Afghanistan</option>
            <option value="Albania">Albania</option>
            <option value="Algeria">Algeria</option>
            <option value="American Samoa">American Samoa</option>
            <option value="Andorra">Andorra</option>
            <option value="Angola">Angola</option>
            <option value="Anguilla">Anguilla</option>
            <option value="Antarctica">Antarctica</option>
            <option value="Antigua And Barbuda">Antigua And Barbuda</option>
            <option value="Argentina">Argentina</option>
            <option value="Armenia">Armenia</option>
            <option value="Aruba">Aruba</option>
            <option value="Australia">Australia</option>
            <option value="Austria">Austria</option>
            <option value="Azerbaijan">Azerbaijan</option>
            <option value="Bahamas">Bahamas</option>
            <option value="Bahrain">Bahrain</option>
            <option value="Bangladesh">Bangladesh</option>
            <option value="Barbados">Barbados</option>
            <option value="Belarus">Belarus</option>
            <option value="Belgium">Belgium</option>
            <option value="Belize">Belize</option>
            <option value="Benin">Benin</option>
            <option value="Bermuda">Bermuda</option>
            <option value="Bhutan">Bhutan</option>
            <option value="Bolivia">Bolivia</option>
            <option value="Bosnia And Herzegowina">
              Bosnia And Herzegowina
            </option>
            <option value="Botswana">Botswana</option>
            <option value="Bouvet Island">Bouvet Island</option>
            <option value="Brazil">Brazil</option>
            <option value="British Indian Ocean Territory">
              British Indian Ocean Territory
            </option>
            <option value="Brunei Darussalam">Brunei Darussalam</option>
            <option value="Bulgaria">Bulgaria</option>
            <option value="Burkina Faso">Burkina Faso</option>
            <option value="Burundi">Burundi</option>
            <option value="Cambodia">Cambodia</option>
            <option value="Cameroon">Cameroon</option>
            <option value="Canada">Canada</option>
            <option value="Canary Islands">Canary Islands</option>
            <option value="Cape Verde">Cape Verde</option>
            <option value="Cayman Islands">Cayman Islands</option>
            <option value="Central African Republic">
              Central African Republic
            </option>
            <option value="Chad">Chad</option>
            <option value="Chile">Chile</option>
            <option value="China">China</option>
            <option value="Christmas Island">Christmas Island</option>
            <option value="Cocos (Keeling) Islands">
              Cocos (Keeling) Islands
            </option>
            <option value="Colombia">Colombia</option>
            <option value="Comoros">Comoros</option>
            <option value="Congo">Congo</option>
            <option value="Cook Islands">Cook Islands</option>
            <option value="Costa Rica">Costa Rica</option>
            <option value="Cote D'Ivoire">Cote D'Ivoire</option>
            <option value="Croatia (Local Name: Hrvatska)">
              Croatia (Local Name: Hrvatska)
            </option>
            <option value="Cuba">Cuba</option>
            <option value="Cyprus">Cyprus</option>
            <option value="Czech Republic">Czech Republic</option>
            <option value="Denmark">Denmark</option>
            <option value="Djibouti">Djibouti</option>
            <option value="Dominica">Dominica</option>
            <option value="Dominican Republic">Dominican Republic</option>
            <option value="East Timor">East Timor</option>
            <option value="Ecuador">Ecuador</option>
            <option value="Egypt">Egypt</option>
            <option value="El Salvador">El Salvador</option>
            <option value="Equatorial Guinea">Equatorial Guinea</option>
            <option value="Eritrea">Eritrea</option>
            <option value="Estonia">Estonia</option>
            <option value="Ethiopia">Ethiopia</option>
            <option value="Falkland Islands (Malvinas)">
              Falkland Islands (Malvinas)
            </option>
            <option value="Faroe Islands">Faroe Islands</option>
            <option value="Fiji">Fiji</option>
            <option value="Finland">Finland</option>
            <option value="France">France</option>
            <option value="French Guiana">French Guiana</option>
            <option value="French Polynesia">French Polynesia</option>
            <option value="French Southern Territories">
              French Southern Territories
            </option>
            <option value="Gabon">Gabon</option>
            <option value="Gambia">Gambia</option>
            <option value="Georgia">Georgia</option>
            <option value="Germany">Germany</option>
            <option value="Ghana">Ghana</option>
            <option value="Gibraltar">Gibraltar</option>
            <option value="Greece">Greece</option>
            <option value="Greenland">Greenland</option>
            <option value="Grenada">Grenada</option>
            <option value="Guadeloupe">Guadeloupe</option>
            <option value="Guam">Guam</option>
            <option value="Guatemala">Guatemala</option>
            <option value="Guinea">Guinea</option>
            <option value="Guinea-Bissau">Guinea-Bissau</option>
            <option value="Guyana">Guyana</option>
            <option value="Haiti">Haiti</option>
            <option value="Heard And Mc Donald Islands">
              Heard And Mc Donald Islands
            </option>
            <option value="Holy See (Vatican City State)">
              Holy See (Vatican City State)
            </option>
            <option value="Honduras">Honduras</option>
            <option value="Hong Kong">Hong Kong</option>
            <option value="Hungary">Hungary</option>
            <option value="Iceland">Iceland</option>
            <option value="India">India</option>
            <option value="Indonesia">Indonesia</option>
            <option value="Iran (Islamic Republic Of)">
              Iran (Islamic Republic Of)
            </option>
            <option value="Iraq">Iraq</option>
            <option value="Ireland">Ireland</option>
            <option value="Israel">Israel</option>
            <option value="Italy">Italy</option>
            <option value="Jamaica">Jamaica</option>
            <option value="Japan">Japan</option>
            <option value="Jordan">Jordan</option>
            <option value="Kazakhstan">Kazakhstan</option>
            <option value="Kenya">Kenya</option>
            <option value="Kiribati">Kiribati</option>
            <option value="Korea, Dem People'S Republic">
              Korea, Dem People'S Republic
            </option>
            <option value="Korea, Republic Of">Korea, Republic Of</option>
            <option value="Kuwait">Kuwait</option>
            <option value="Kyrgyzstan">Kyrgyzstan</option>
            <option value="Lao People'S Dem Republic">
              Lao People'S Dem Republic
            </option>
            <option value="Latvia">Latvia</option>
            <option value="Lebanon">Lebanon</option>
            <option value="Lesotho">Lesotho</option>
            <option value="Liberia">Liberia</option>
            <option value="Libyan Arab Jamahiriya">
              Libyan Arab Jamahiriya
            </option>
            <option value="Liechtenstein">Liechtenstein</option>
            <option value="Lithuania">Lithuania</option>
            <option value="Luxembourg">Luxembourg</option>
            <option value="Macau">Macau</option>
            <option value="Macedonia">Macedonia</option>
            <option value="Madagascar">Madagascar</option>
            <option value="Malawi">Malawi</option>
            <option value="Malaysia">Malaysia</option>
            <option value="Maldives">Maldives</option>
            <option value="Mali">Mali</option>
            <option value="Malta">Malta</option>
            <option value="Marshall Islands">Marshall Islands</option>
            <option value="Martinique">Martinique</option>
            <option value="Mauritania">Mauritania</option>
            <option value="Mauritius">Mauritius</option>
            <option value="Mayotte">Mayotte</option>
            <option value="Mexico">Mexico</option>
            <option value="Micronesia, Federated States">
              Micronesia, Federated States
            </option>
            <option value="Moldova, Republic Of">Moldova, Republic Of</option>
            <option value="Monaco">Monaco</option>
            <option value="Mongolia">Mongolia</option>
            <option value="Montserrat">Montserrat</option>
            <option value="Morocco">Morocco</option>
            <option value="Mozambique">Mozambique</option>
            <option value="Myanmar">Myanmar</option>
            <option value="Namibia">Namibia</option>
            <option value="Nauru">Nauru</option>
            <option value="Nepal">Nepal</option>
            <option value="Netherlands">Netherlands</option>
            <option value="Netherlands Ant Illes">Netherlands Ant Illes</option>
            <option value="New Caledonia">New Caledonia</option>
            <option value="New Zealand">New Zealand</option>
            <option value="Nicaragua">Nicaragua</option>
            <option value="Niger">Niger</option>
            <option value="Nigeria">Nigeria</option>
            <option value="Niue">Niue</option>
            <option value="Norfolk Island">Norfolk Island</option>
            <option value="Northern Mariana Islands">
              Northern Mariana Islands
            </option>
            <option value="Norway">Norway</option>
            <option value="Oman">Oman</option>
            <option value="Pakistan">Pakistan</option>
            <option value="Palau">Palau</option>
            <option value="Panama">Panama</option>
            <option value="Papua New Guinea">Papua New Guinea</option>
            <option value="Paraguay">Paraguay</option>
            <option value="Peru">Peru</option>
            <option value="Philippines">Philippines</option>
            <option value="Pitcairn">Pitcairn</option>
            <option value="Poland">Poland</option>
            <option value="Portugal">Portugal</option>
            <option value="Puerto Rico">Puerto Rico</option>
            <option value="Qatar">Qatar</option>
            <option value="Reunion">Reunion</option>
            <option value="Romania">Romania</option>
            <option value="Russian Federation">Russian Federation</option>
            <option value="Rwanda">Rwanda</option>
            <option value="Saint K Itts And Nevis">
              Saint K Itts And Nevis
            </option>
            <option value="Saint Lucia">Saint Lucia</option>
            <option value="Saint Vincent, The Grenadines">
              Saint Vincent, The Grenadines
            </option>
            <option value="Samoa">Samoa</option>
            <option value="San Marino">San Marino</option>
            <option value="Sao Tome And Principe">Sao Tome And Principe</option>
            <option value="Saudi Arabia">Saudi Arabia</option>
            <option value="Senegal">Senegal</option>
            <option value="Seychelles">Seychelles</option>
            <option value="Sierra Leone">Sierra Leone</option>
            <option value="Singapore">Singapore</option>
            <option value="Slovakia">Slovakia</option>
            <option value="Slovenia">Slovenia</option>
            <option value="Solomon Islands">Solomon Islands</option>
            <option value="Somalia">Somalia</option>
            <option value="South Africa">South Africa</option>
            <option value="South Georgia , S Sandwich Is.">
              South Georgia , S Sandwich Is.
            </option>
            <option value="Spain">Spain</option>
            <option value="Sri Lanka">Sri Lanka</option>
            <option value="St. Helena">St. Helena</option>
            <option value="St. Pierre And Miquelon">
              St. Pierre And Miquelon
            </option>
            <option value="Sudan">Sudan</option>
            <option value="Suriname">Suriname</option>
            <option value="Svalbard, Jan Mayen Islands">
              Svalbard, Jan Mayen Islands
            </option>
            <option value="Sw Aziland">Sw Aziland</option>
            <option value="Sweden">Sweden</option>
            <option value="Switzerland">Switzerland</option>
            <option value="Syrian Arab Republic">Syrian Arab Republic</option>
            <option value="Taiwan">Taiwan</option>
            <option value="Tajikistan">Tajikistan</option>
            <option value="Tanzania, United Republic Of">
              Tanzania, United Republic Of
            </option>
            <option value="Thailand">Thailand</option>
            <option value="Togo">Togo</option>
            <option value="Tokelau">Tokelau</option>
            <option value="Tonga">Tonga</option>
            <option value="Trinidad And Tobago">Trinidad And Tobago</option>
            <option value="Tunisia">Tunisia</option>
            <option value="Turkey">Turkey</option>
            <option value="Turkmenistan">Turkmenistan</option>
            <option value="Turks And Caicos Islands">
              Turks And Caicos Islands
            </option>
            <option value="Tuvalu">Tuvalu</option>
            <option value="Uganda">Uganda</option>
            <option value="Ukraine">Ukraine</option>
            <option value="United Arab Emirates">United Arab Emirates</option>
            <option value="United Kingdom">United Kingdom</option>
            <option value="United States">United States</option>
            <option value="United States Minor Is.">
              United States Minor Is.
            </option>
            <option value="Uruguay">Uruguay</option>
            <option value="Uzbekistan">Uzbekistan</option>
            <option value="Vanuatu">Vanuatu</option>
            <option value="Venezuela">Venezuela</option>
            <option value="Viet Nam">Viet Nam</option>
            <option value="Virgin Islands (British)">
              Virgin Islands (British)
            </option>
            <option value="Virgin Islands (U.S.)">Virgin Islands (U.S.)</option>
            <option value="Wallis And Futuna Islands">
              Wallis And Futuna Islands
            </option>
            <option value="Western Sahara">Western Sahara</option>
            <option value="Yemen">Yemen</option>
            <option value="Serbia">Serbia</option>
            <option value="Zaire">Zaire</option>
            <option value="Zambia">Zambia</option>
            <option value="Zimbabwe">Zimbabwe</option>
          </select>
          <div className="resister-input-group">
            <input
              required
              type="text"
              name="address"
              className="resister-input"
              placeholder="Address"
              id="address"
              value={address}
              onChange={handleChange}
            />
            <input
              required
              type="text"
              name="city"
              className="resister-input"
              placeholder="City"
              id="city"
              value={city}
              onChange={handleChange}
            />
          </div>
          <div className="resister-input-group">
            <input
              required
              type="text"
              name="state"
              className="resister-input"
              placeholder="State"
              id="state"
              value={state}
              onChange={handleChange}
            />
            <input
              required
              type="text"
              name="postal"
              className="resister-input"
              placeholder="Postal"
              id="postal"
              value={postal}
              onChange={handleChange}
            />
          </div>

          <div className="resister-checkbox">
            <input
              required
              type="checkbox"
              className="resister-checkbox-input"
              name="tos"
              value={{}}
              onChange={(event) => {
                const target = event.target;
                const value =
                  target.type === "checkbox" ? target.checked : target.value;
                setFormData({ ...formData, tos: value });
              }}
            />
            <label>
              You Agree To Our <a href="#">Terms Of Service</a>.
            </label>
          </div>
          <div className="resister-checkbox">
            <input
              checked={updateviaemail}
              type="checkbox"
              className="resister-checkbox-input"
              name="updateviaemail"
              value={updateviaemail}
              onChange={(event) => {
                const target = event.target;
                const value =
                  target.type === "checkbox" ? target.checked : target.value;
                setFormData({ ...formData, updateviaemail: value });
              }}
            />
            <label>Get Updates Via Email.</label>
          </div>

          <button type="submit" className="button-resistation">
            {loading ? "LOADING.." : "COMPETE RESISTRATION"}
          </button>
        </form>
        <div className="resister-text-center">
          Already Have an Account <Link to="/login">Sign In</Link>
        </div>
      </div>
    </div>
  );
};

export default Resister;
