import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "../css/Login.css";
import QueenSmtpImage from "../../images/queensmtpfinal.png";
import myAxiosGlobal from "../MyAxios/MyAxiosGlobal";

const UserActivate = () => {
  const history = useHistory();
  const { id } = useParams();
  useEffect(() => {
    myAxiosGlobal
      .get(`/api/user/activate/${id}`)
      .then((response) => {
        console.log(response.data);
        history.push("/dashboard");
      })
      .catch((error) => {
        toast.error(error?.response?.data);
      });
  }, []);
  return (
    <div className="login-main-container">
      <div
        className="login-container"
        style={{
          width: "50%",
          height: "320px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={QueenSmtpImage}
          alt="stripe-image"
          className="login-logo"
          style={{ marginBottom: "30px", marginTop: "-30px" }}
        />
        <h2 style={{ textAlign: "center", marginTop: "0px", color: "green" }}>
          {"Loading..."}
        </h2>
      </div>
    </div>
  );
};

export default UserActivate;
