import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { EmailContext } from "../../contexts/EmailProvider";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { FaLock } from "react-icons/fa";
import "./SignupModal.css";

import SignUp from "./SignUp";
import SignIn from "./SignIn";

function SignupModal() {
  const history = useHistory();
  const { isOpen, setIsOpen, thankuModal, setThankuModal, signin, setSignin } =
    useContext(EmailContext);

  //local state
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [required, setRequired] = useState(false);

  return (
    <>
      <ToastContainer />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        style={{ overflow: "scroll" }}
      >
        <Fade in={isOpen} className="payment-modal-container">
          <div className="payment-modal-container-inner">
            <div className="modal-content">
              <div className="modal-header-signup">
                <button
                  type="button"
                  className="close"
                  onClick={() => setIsOpen(false)}
                >
                  &times;
                </button>
                {setSignin ? (
                  <p className="payment-top text-center">
                    A LINK WILL BE SEND TO YOUR EMAIL, VERIFY EMAIL AND GET 200
                    BULK VERIFY CREDITS DAILY BY EMAIL
                  </p>
                ) : (
                  <p className="payment-top text-center">
                    A LINK WILL BE SEND TO YOUR EMAIL, VERIFY EMAIL AND GET 200
                    BULK VERIFY CREDITS DALLY BY EMAIL
                  </p>
                )}

                <div id="payment-errors"></div>
              </div>
              {signin ? (
                <SignIn
                  email={email}
                  setEmail={setEmail}
                  password={password}
                  setPassword={setPassword}
                  loading={loading}
                  setLoading={setLoading}
                  required={required}
                  setSignin={setSignin}
                  setIsOpen={setIsOpen}
                  thankuModal={thankuModal}
                  setThankuModal={setThankuModal}
                />
              ) : (
                <SignUp
                  name={name}
                  setName={setName}
                  email={email}
                  setEmail={setEmail}
                  password={password}
                  setPassword={setPassword}
                  loading={loading}
                  setLoading={setLoading}
                  required={required}
                  setSignin={setSignin}
                  setIsOpen={setIsOpen}
                  thankuModal={thankuModal}
                  setThankuModal={setThankuModal}
                />
              )}

              <div className="payment-footer-signup">
                <div className="payment">
                  <FaLock className="lock-icon" />{" "}
                  <h5>
                    100% Free Email Verifier <span></span>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}

export default SignupModal;
