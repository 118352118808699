import React from "react";
import "../css/DashboardFooter.css";

function DashboardFooter() {
  return (
    <footer className="dashboard-footer">
      <div className="dashboard-container">
        © {new Date().getFullYear()} QUEENSMTP ALL RIGHTS RESERVED
      </div>
    </footer>
  );
}

export default DashboardFooter;
