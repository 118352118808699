import React, { useState, useEffect, useContext } from "react";
import myAxios from "../MyAxios/MyAxios";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import ProfileHead from "./ProfileHead";
import CountrySelect from "./SelectOptions/CountrySelect";
import { UserContext } from "../../contexts/UserProvider";
import { toast, ToastContainer } from "react-toastify";

import "./ContactInfo.css";

const ContactInfo = () => {
  const [loading, setLoading] = useState(false);
  const { getUser, userData } = useContext(UserContext);
  useEffect(() => {
    document.title = `Contact | Free Email Verifier`;
    getUser();
  }, []);
  console.log("userData", userData);
  const [firstName, setFirstName] = useState(
    `${userData.profile_firstname ? userData.profile_firstname : ""}`
  );
  const [lastName, setLastName] = useState(
    `${userData.profile_lastname ? userData.profile_lastname : ""}`
  );
  const [email, setEmail] = useState(`${userData.email ? userData.email : ""}`);
  const [address1, setAddress1] = useState(
    `${
      userData.billing_address?.address1
        ? userData.billing_address?.address1
        : ""
    }`
  );
  const [address2, setAddress2] = useState(
    `${
      userData.billing_address?.address2
        ? userData.billing_address?.address2
        : ""
    }`
  );
  const [city, setCity] = useState(
    `${userData.billing_address?.city ? userData.billing_address?.city : ""}`
  );
  const [postal, setPostal] = useState(
    `${
      userData.billing_address?.postal ? userData.billing_address?.postal : ""
    }`
  );
  const [website, setWebsite] = useState(
    `${userData.website ? userData.website : ""}`
  );
  const [mobile, setMobile] = useState(
    `${userData.mobile_number ? userData.mobile_number : ""}`
  );
  const [country, setCountry] = useState(
    `${
      userData.billing_address?.country ? userData.billing_address?.country : ""
    }`
  );
  const [company, setCompany] = useState(
    `${userData.company ? userData.company : ""}`
  );
  const [phone, setPhone] = useState("");
  const [stateName, setStateName] = useState(
    `${userData.billing_address?.state ? userData.billing_address?.state : ""}`
  );

  const [billingAddress, setBillingAddress] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    let contactData = {
      profile_firstname: firstName,
      profile_lastname: lastName,
      email: email,
      address1: address1,
      address2: address2,
      city: city,
      postal: postal,
      website: website,
      mobile: mobile,
      country: country,
      company: company,
      phone: phone,
      state: stateName,
    };
    myAxios
      .post(`/api/update/contact/${localStorage.getItem("vmid")}`, contactData)
      .then((response) => {
        toast.success(response.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.response.data);
        setLoading(false);
      });
  };

  return (
    <div className="contact-info-container">
      <ToastContainer />
      <ProfileHead
        firstName={userData.profile_firstname}
        lastName={userData.profile_lastname}
      />
      <h2 className="contact-info-title">Primary account contact</h2>
      <form onSubmit={handleSubmit}>
        <div className="contact-form-container">
          <div className="contact-form-container-left">
            <div className="contact-form-name">
              <div className="input-group">
                <label>
                  First Name <span>*</span>
                </label>
                <input
                  className="firstname"
                  type="text"
                  placeholder="First Name"
                  name="firstName"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div className="input-group">
                <label>
                  Last Name <span>*</span>
                </label>
                <input
                  className="lastname"
                  type="text"
                  placeholder="Last Name"
                  name="lastName"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </div>
            <div className="input-group">
              <label>
                Email address (at work) <span>*</span>
              </label>
              <input
                className="lastname"
                type="text"
                placeholder="example@example.com"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="input-group">
              <label>
                Address <span>*</span>
              </label>
              <input
                className="lastname"
                type="text"
                placeholder="99 Wall Street #112"
                name="address1"
                value={address1}
                onChange={(e) => setAddress1(e.target.value)}
              />
            </div>
            {/* <div className="input-group">
              <label>Address 2</label>
              <input
                className="lastname"
                type="text"
                name="address2"
                value={address2}
                onChange={(e) => setAddress2(e.target.value)}
              />
            </div> */}
          </div>

          <div className="contact-form-container-right">
            <div className="contact-form-name">
              <div className="input-group">
                <label>
                  City <span>*</span>
                </label>
                <input
                  className="firstname"
                  type="text"
                  placeholder="New York"
                  name="city"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </div>
              <div className="input-group">
                <label>
                  Zip / Postal code <span>*</span>
                </label>
                <input
                  className="lastname"
                  type="text"
                  placeholder="10005"
                  name="postal"
                  value={postal}
                  onChange={(e) => setPostal(e.target.value)}
                />
              </div>
            </div>
            <div className="input-group">
              <label>State / Province / Region</label>
              <input
                className="lastname"
                type="text"
                name="state"
                placeholder="NY"
                value={stateName}
                onChange={(e) => setStateName(e.target.value)}
              />
            </div>
            <div className="input-group">
              <label>
                Country<span>*</span>
              </label>
              <CountrySelect
                placeholder="United States"
                name="country"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              />
            </div>
          </div>
        </div>
        <button className="form-button" type="submit">
          <SaveAltIcon /> {loading ? "Loading" : "Save"}
        </button>
      </form>
    </div>
  );
};

export default ContactInfo;
