import { useEffect, useState, useContext } from "react";
import moment from "moment";
import myAxios from "../MyAxios/MyAxios";
import "../css/BounceMessage.css";

const BounceMessage = () => {
  const [messages, setMessages] = useState([]);
  const [activeMessage, setActiveMessage] = useState("");
  const [updatedDate, setUpdatedDate] = useState("");
  const [totalMessages, setTotalMessages] = useState(0);
  const [currentSize, setCurrentSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [notBounceMessages, setNotBounceMessages] = useState("");
  useEffect(async () => {
    await myAxios
      .post(`/api/bouncemessage/${localStorage.getItem("vmid")}`, {
        size: currentSize,
        page: currentPage,
      })
      .then(({ data }) => {
        setTotalMessages(data.TotalMessage);
        setMessages(data.data);
        setActiveMessage("");
      })
      .catch((err) => {
        setNotBounceMessages(err.response.data.mgs);
      });
  }, [currentPage]);

  return (
    <div className="container">
      {notBounceMessages ? (
        <div className="bounceMessage">
          <h1>{notBounceMessages}</h1>
        </div>
      ) : (
        <div className="messaging">
          <div className="inbox_msg">
            <div className="inbox_people">
              <div className="inbox_chat">
                {messages &&
                  messages.map((message, index) => (
                    <div
                      key={index}
                      className="chat_list"
                      onClick={() => {
                        setActiveMessage(message.message);
                        setUpdatedDate(message.updatedDate);
                      }}
                    >
                      <div className="chat_people">
                        <div className="chat_ib">
                          <h5>
                            Bounce Message{" "}
                            <span className="chat_date">
                              {moment(message.updatedDate).format(" Do MMM")}
                            </span>
                          </h5>
                          <p>{message.message.slice(0, 40)}</p>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            <div className="mesgs">
              <div className="msg_history">
                <div className="incoming_msg">
                  <h4 className="total-bounce-message">
                    Total Number of Bounce Messages{" "}
                    {totalMessages && totalMessages}
                  </h4>
                  <div className="received_msg">
                    <div className="received_withd_msg">
                      {activeMessage && (
                        <>
                          {" "}
                          {activeMessage}
                          <span className="time_date">
                            {" "}
                            {moment(updatedDate).format(" Do MMM YYYY")}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="pagination">
        <div
          className={`previous ${currentPage <= 1 ? "disabled" : null}`}
          onClick={() => {
            setNotBounceMessages("");
            if (currentPage < 1) return;
            setCurrentPage((currentPage) => currentPage - 1);
          }}
        >
          &laquo; Previous
        </div>
        <div
          className={`next ${
            currentSize * currentPage > totalMessages ? "disabled" : null
          }`}
          onClick={() => {
            if (currentSize * currentPage > totalMessages) return;
            setCurrentPage((currentPage) => currentPage + 1);
          }}
        >
          Next &raquo;
        </div>
      </div>
    </div>
  );
};

export default BounceMessage;
