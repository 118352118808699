import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import MyAxiosM from "../MyAxios/MyAxiosM";
import { toast, ToastContainer } from "react-toastify";

import "../css/BulkVerifier.css";

const BulkVerifier = () => {
  const history = useHistory();
  const [emails, setEmails] = useState([]);
  const [loading, setLoading] = useState(false);
  const userid = localStorage.getItem("vmid");

  const BulkMessageSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (!emails || emails.length === 0) {
      return toast.error("Please enter emails");
    }
    let emails_arr = emails.split("\n");

    MyAxiosM.post(`/verify-bulk/${userid}`, { emails: emails_arr })
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        console.log("clicked");
        history.push(`/account/bulk-verifier/details/${userid}`, response.data);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  return (
    <div className="bulk-verifier-container">
      <ToastContainer />
      <form>
        <textarea
          className="bulk-verifier-textarea"
          placeholder="Paste your emails line by line
example@example.com
example@yourdomain.com
example@gmail.com"
          onChange={(e) => setEmails(e.target.value)}
        ></textarea>
        <button className="bulk-verifier-submit" onClick={BulkMessageSubmit}>
          {loading ? "Loading..." : "Submit Emails"}
        </button>
      </form>
    </div>
  );
};

export default BulkVerifier;
