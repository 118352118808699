import React, { useEffect } from "react";
import "../css/Faqs.css";

const Faqs = () => {
  useEffect(() => {
    document.title = `Faqs | Free Email Verifier`;
  }, []);
  return (
    <div className="faqs-container">
      <h2 className="faqs-heading">faq</h2>
      <hr />
      <div className="faqs-body">
        <div className="faqs-item">
          <h4>Is this legal to buy YouTube Likes and Views?</h4>
          <p>
            Absolutely Yes. Like YouTube most of the Social Platforms give a
            opportunity to promote any business by real ad campaign. When we
            bring you Likes and Views from YouTube Ad Campaign there should be
            no reason of being confused. This is legal and safe.
          </p>
        </div>
        <div className="faqs-item">
          <h4>Are those visitors will increase my Channel watch time?</h4>
          <p>
            Yes, it will Increase your Channel Watch Time. We pride ourselves
            for offering the highest viewer retention rate in the industry – our
            views float between 87–97% retention
          </p>
        </div>
        <div className="faqs-item">
          <h4>Can I earn money with Adsense?</h4>
          <p>
            No. Our main goal is to deliver views/likes to your video, quickly
            and safely. Adsense makes our service less effective and decreases
            conversion rate, so we recommend disabling it while our YouTube
            Views campaign runs.
          </p>
        </div>
        <div className="faqs-item">
          <h4>How much time it usually takes to show the results?</h4>
          <p>
            We really care about our clients Satisfaction. Our Ad Campaign
            Experts run ad campaign within a few hours after any order. But
            YouTube takes 24 hours to show the results
          </p>
        </div>
        <div className="faqs-item">
          <h4>Will my video get removed?</h4>
          <p>
            We deliver real views use safe Ad campaign, so your video will never
            be removed or taken down due to our service.
          </p>
        </div>
        <div className="faqs-item">
          <h4>Is there Fast, Organic & Social options?</h4>
          <p>
            We recently revamped our YouTube views and simplified it. Social
            Views is not an integrated part with all of our packages, and
            delivery speed options can be customized by contacting our Service
            team.
          </p>
        </div>
        <div className="faqs-item">
          <h4>Is this legal to buy YouTube Likes and Views?</h4>
          <p>
            Absolutely Yes. Like YouTube most of the Social Platforms give a
            opportunity to promote any business by real ad campaign. When we
            bring you Likes and Views from YouTube Ad Campaign there should be
            no reason of being confused. This is legal and safe.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Faqs;
