import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import React from "react";
import { Link } from "react-router-dom";
import "../css/DomainTable.css";

const DomainTable = ({
  id,
  name,
  email_verified,
  dkim_verified,
  spf_verified,
  createdAt,
}) => {
  return (
    <div className="domain-main-container">
      <div
        className={`domain-table-container ${
          dkim_verified && spf_verified && "lightgreen"
        }`}
      >
        <div className="domain-table-title">
          <h4>{name}</h4>
        </div>

        <div className="domain-table-dkim">
          {dkim_verified ? <CheckIcon className="green" /> : <ClearIcon />}
          <p>DKIM verification</p>
        </div>
        <div className="domain-table-spf">
          {spf_verified ? <CheckIcon className="green" /> : <ClearIcon />}
          <p>SPF verification</p>
        </div>
        <div className="domain-table-btn-group">
          <div className="domain-table-active">
            <button
              className={`active-btn ${
                dkim_verified && spf_verified ? "green" : null
              }`}
            >
              {dkim_verified && spf_verified ? "Active" : "Inactive"}
            </button>
          </div>
          <div className="domain-table-btn-inner">
            <Link to={`/sending_domain/view/${id}`}>
              <button
                className={`view ${
                  dkim_verified && spf_verified ? "green" : null
                }`}
              >
                View Details
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DomainTable;
