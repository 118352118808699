import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { LoginContext } from "../../contexts/LogingProvider";
import LogoImage from "../../images/emailverifier.png";
import myAxiosGlobal from "../MyAxios/MyAxiosGlobal";

import "../css/Login.css";

const Login = () => {
  const { handleLogin, error, verifyToken } = useContext(LoginContext);
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [bulkButton, setBulkButton] = useState("");

  useEffect(() => {
    document.title = `Login | Free Email Verifier`;
    verifyToken();
    setBulkButton(location?.state?.state);
  }, []);

  const handleLoginForm = (e) => {
    e.preventDefault();
    if (email === "" || password === "") {
      return toast.error("Please fill all the fields");
    }
    setLoading(true);
    myAxiosGlobal
      .post("/api/login", {
        email: email,
        password: password,
      })
      .then(function (response) {
        localStorage.setItem("vmtoken", response.data.refreshToken);
        localStorage.setItem("vmuser", response.data.user.email);
        localStorage.setItem("vmid", response.data.user._id);
        localStorage.setItem("isSignup", true);
        if (bulkButton === "bulk") {
          history.push("/account/bulk-verifier");
        } else {
          window.location.href = "/dashboard";
        }

        setLoading(false);
      })
      .catch(function (error) {
        toast.error("Invalid email or password");
        setLoading(false);
      });
    setEmail("");
    setPassword("");
  };
  const history = useHistory();
  const homepage = () => history.push("/");
  return (
    <div className="login-main-container">
      <ToastContainer />
      <form className="login-container" onSubmit={handleLoginForm}>
        <img
          src={LogoImage}
          alt="stripe-image"
          className="login-logo"
          onClick={homepage}
        />
        <div className="login-error">
          <p>{error ? error : null}</p>
        </div>
        <div className="login-card">
          <input
            type="text"
            placeholder="Username"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button className="login-btn">
            {loading ? "Loding..." : "LOG IN"}
          </button>
          <p className="forgot-text">
            <Link to="/signup/free">Create Account</Link> or{" "}
            <Link to="/forgot">Forgot Password</Link>
          </p>
        </div>
      </form>
    </div>
  );
};

export default Login;
