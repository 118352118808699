import React, { useEffect, useState, useContext } from "react";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import YouTubeIcon from "@material-ui/icons/YouTube";
import { AiOutlineArrowRight } from "react-icons/ai";
import copy from "copy-to-clipboard";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import myAxios from "../../componends/MyAxios/MyAxios";
import DomainHead from "./DomainHead";
import { UserContext } from "../../contexts/UserProvider";
import "../css/SingleDomain.css";

const SingleDomain = () => {
  const [domain, setDomain] = useState();
  const [loadingdkm, setLoadingdkm] = useState(false);
  const { id } = useParams();
  const { getUser, setLoading } = useContext(UserContext);

  const verifySPF = () => {
    myAxios
      .get(`/api/domain/spf-verify/${id}/${localStorage.getItem("vmid")}`)
      .then(({ data }) => {
        toast(data);
        let domainData = { ...domain };
        domainData.domain.spf_verified = true;
        setDomain({ ...domainData });
      })
      .catch(({ response }) => toast.error(response.data));
  };

  const verifyDKIM = () => {
    setLoadingdkm(true);
    myAxios
      .get(`/api/domain/spf-dkim/${id}/${localStorage.getItem("vmid")}`)
      .then(({ data }) => {
        toast(data);
        let domainData = { ...domain };
        domainData.domain.dkim_verified = true;
        setDomain({ ...domainData });
        setLoadingdkm(false);
      })
      .catch(({ response }) => {
        toast.error(response.data);
        setLoadingdkm(false);
      });
  };

  const clickToCopy = (value) => {
    copy(value);
    toast.success("Copied");
    //alert("Copied");
  };

  useEffect(() => {
    document.title = `Sending Domain View | QUEENSMTP`;
    setLoading(true);
    myAxios
      .get(`/api/domain/detail/${id}/${localStorage.getItem("vmid")}`)
      .then(({ data }) => {
        setDomain(data);
        setLoading(false);
      })
      .catch(({ response }) => {
        console.log(response.data);
        setLoading(false);
      });

    console.log();
  }, []);

  useEffect(() => {
    getUser();
  }, []);
  return (
    <div className="single-domain-container">
      <ToastContainer />
      <div className="single-domain-head">
        <div className="single-domain-head-left">
          Domain verify is pretty easy. You can the video tutorials{" "}
          <AiOutlineArrowRight className="arrow-icons" />
        </div>
        <div className="single-domain-head-right">
          <div className="single-domain-head-youtube">
            <a href="#">
              <YouTubeIcon className="youtube-icon" /> Tutorial Video
            </a>
          </div>
        </div>
      </div>
      <div className="single-domain-body-head">
        <h2>{domain?.domain.name} SPF and DKIM verification</h2>
        <p>
          Add the following information to your domain's DNS records to confirm
          that you own it and to prevent others from using it. Hit the Verify
          Now button once you are done. Note that it may take up to 2 days for
          the DNS update to fully propagate. You will also need to add a
          DomainKeys Identified Mail (DKIM) record, which is a way to authorize
          your email. Once you're done, any outgoing email whose FROM email
          address matches the domain will be signed and can be verified by the
          recipient's server.
        </p>
      </div>

      <div className="domain-verification-container">
        <table>
          <tr>
            <th>Type</th>
            <th>Host Name</th>
            <th>value</th>
            <th></th>
          </tr>
          {domain && (
            <tr>
              <td>
                <button
                  className={
                    domain.domain.spf_verified ? "btn-text green" : "btn-text"
                  }
                >
                  TXT
                </button>
              </td>
              <td>
                <p>
                  {domain.domain.name}
                  <FileCopyIcon
                    className="copy-icon"
                    onClick={() => clickToCopy(domain.domain.name)}
                  />
                </p>
              </td>
              <td>
                <p>
                  {domain.record}{" "}
                  <FileCopyIcon
                    className="copy-icon"
                    onClick={() => clickToCopy(domain.record)}
                  />
                </p>
              </td>
              <td>
                <button
                  className={
                    domain.domain.spf_verified
                      ? "pending-btn green"
                      : "pending-btn"
                  }
                >
                  {domain.domain.spf_verified ? "Active" : "Pending"}
                </button>
              </td>
            </tr>
          )}

          {domain && (
            <tr>
              <td>
                <button
                  className={
                    domain.domain.dkim_verified ? "btn-text green" : "btn-text"
                  }
                >
                  TXT
                </button>
              </td>
              <td>
                <p>
                  {domain.domain.dkim_host_name}
                  <FileCopyIcon
                    className="copy-icon"
                    onClick={() => clickToCopy(domain.domain.dkim_host_name)}
                  />
                </p>
              </td>
              <td style={{ display: "flex" }}>
                <p className="dkim-paragraph">
                  {domain.domain.dkim_record}
                  {/* <FileCopyIcon
                    className="copy-icon"
                    onClick={() => clickToCopy(domain.domain.dkim_record)}
                  /> */}
                </p>
                <FileCopyIcon
                  className="copy-icon"
                  onClick={() => clickToCopy(domain.domain.dkim_record)}
                />
              </td>
              <td>
                <button
                  className={
                    domain.domain.dkim_verified
                      ? "pending-btn green"
                      : "pending-btn"
                  }
                >
                  {domain.domain.dkim_verified ? "Active" : "Pending"}
                </button>
              </td>
            </tr>
          )}
        </table>
      </div>
      {/* <div className="verify-now-btn">
        <button onClick={verifySPF}>Verify SPF Now</button>
      </div> */}
      {domain && !domain.domain.dkim_verified && (
        <div className="verify-now-btn">
          <button onClick={verifyDKIM}>
            {loadingdkm ? "Loading ..." : "Verify DKIM Now"}
          </button>
        </div>
      )}
    </div>
  );
};

export default SingleDomain;
