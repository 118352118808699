import React from "react";
import myAxiosGlobal from "../MyAxios/MyAxiosGlobal";
import { toast, ToastContainer } from "react-toastify";

const SignIn = ({
  email,
  setEmail,
  password,
  setPassword,
  loading,
  setLoading,
  required,
  setSignin,
  setIsOpen,
  thankuModal,
  setThankuModal,
}) => {
  const HandleLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    if (email === "" || password === "") {
      return toast.error("Please fill all the fields");
    }
    myAxiosGlobal
      .post("/api/login", {
        email: email,
        password: password,
      })
      .then(function (response) {
        localStorage.setItem("vmtoken", response.data.refreshToken);
        localStorage.setItem("vmuser", response.data.user.email);
        localStorage.setItem("vmid", response.data.user._id);

        toast.success("Login Successful");
        if (response.data.user.activate) {
          localStorage.setItem("isSignup", true);
          localStorage.setItem("signupemailsend", 1);
        }
        setIsOpen(false);
        setLoading(false);
        setEmail("");
        setPassword("");
        window.location.reload();
      })
      .catch(function (error) {
        setLoading(false);
        console.log("error from login", error);
      });
  };
  return (
    <div className="modal-body">
      <ToastContainer />
      <form className="paymentFrm">
        <div className="form-group">
          <label>Email</label>
          <input
            type="text"
            placeholder="Email"
            className={
              email === "" && required
                ? "form-control red-error-border"
                : "form-control"
            }
            name="emial"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Password</label>
          <input
            type="password"
            placeholder="Password"
            className={
              password === "" && required
                ? "form-control red-error-border"
                : "form-control"
            }
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <div className="form-group">
          <button
            type="submit"
            className={
              loading
                ? "payment-success-button payment-success-button-signup disabled"
                : "payment-success-button payment-success-button-signup"
            }
            onClick={HandleLogin}
          >
            {loading ? "Loading.." : "SignIN Now!"}
          </button>
        </div>
        <div className="account-footer">
          Need an Account? <span onClick={() => setSignin(false)}>SIGN UP</span>
        </div>
      </form>
    </div>
  );
};

export default SignIn;
