import React, { useState, useEffect, useContext } from "react";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { toast, ToastContainer } from "react-toastify";
import { UserContext } from "../../contexts/UserProvider";
import myAxios from "../MyAxios/MyAxios";
import "./Api.css";

const Api = () => {
  const [newPassword, setNewPassword] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { userData, getUser } = useContext(UserContext);
  const { smtp_username, smtp_userpass } = userData;

  const generatePassword = () => {
    setIsLoading(true);
    myAxios
      .post("/api/generatesmtppassword", { _id: localStorage.getItem("vmid") })
      .then((response) => {
        setIsLoading(false);
        setNewPassword(response.data.new_password);
        toast.success(response.data.success_message);
      })
      .catch((error) => {
        setIsLoading(false);
        setToastMessage(error.response.data);
      });
  };

  useEffect(() => {
    document.title = `Api & SMTP | Free Email Verifier`;
    getUser();
  }, []);

  return (
    <div className="api-container">
      {/* <ProfileHead /> */}
      <ToastContainer />
      {/* <div className="api-table-container">
        <p style={{ color: "red" }}>
          Information bellow to confirm your SMTP client. Click generate
          password button for smtp password
        </p>
        <table id="customers">
          <thead>
            <tr>
              <th>SMTP HOST</th>
              <th>SMTP PORT</th>
              <th>SMTP ENCRYPTION</th>
              <th>SMTP USERNAME</th>
              <th>SMTP PASSWORD</th>
              <th>GENERATE PASSWORD</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>smtp.QUEENSMTP</td>
              <td>2525</td>
              <td>STARTTLS</td>
              <td>{smtp_username && smtp_username}</td>
              <td>
                {newPassword ? newPassword : smtp_userpass && smtp_userpass}
              </td>
              <td>
                <div className="generate-smtp-password">
                  <button onClick={generatePassword}>
                    {isLoading ? "Loading..." : "generate password"}
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div> */}

      <div className="api-docs-container">
        {/* <div className="api-item">
          <h4 className="red">Please Note: API comming soon </h4>
        </div> */}
        <div className="api-item">
          <h4>API Endpoint</h4>
          <p className="red">https://api.free-emailverifier.com/api/v1</p>
        </div>
        <div className="api-item">
          <h4>Your API token</h4>
          <p className="red">0gXOwDsOuRatvFjZHmerLc75ZsP</p>
        </div>
        <div className="api-docs-message">
          <p>
            API URL https://api.free-emailverifier.com/api/v1/API-token/emails
            <br />
            <br />
            Send post request to the URL
            <br />
            <br />
            Example:
            https://api.free-emailverifier.com/api/v1/0gXOwDsOuRatvFjZHmerLc75ZsP/example@example.com
            <br />
            <br />
            You can send emails array to the URL
          </p>
        </div>
        <div className="api-docs-btn">
          <button>Renew token</button>
        </div>
        <div className="api-item">
          {/* <h4>API docs</h4>
          <a href="#">
            https://api.free-emailverifier.com/api/v1
            <FileCopyIcon />
          </a> */}
        </div>
      </div>
    </div>
  );
};

export default Api;
