import CloseIcon from "@material-ui/icons/Close";
import React, { useContext, useState } from "react";
import myAxios from "../../componends/MyAxios/MyAxios";
import { ToggleContext } from "../../contexts/ToggleProvider";
import "../css/PackageModal.css";

const PackageModal = ({
  planlocal,
  userData,
  success,
  refuse,
  getUser,
  getNotificationCount,
}) => {
  const [loading, setLoading] = useState(false);
  const { setPackageModal } = useContext(ToggleContext);

  const [name, setName] = useState(
    `${userData && userData.profile_firstname} ${
      userData && userData.profile_lastname
    }`
  );
  const [cardNumber, setCardNumber] = useState(
    userData && userData.stripe && userData.stripe.last4
  );
  const [expMonth, setExpMonth] = useState(
    userData && userData.stripe && userData.stripe.exp_month
  );
  const [expYear, setExpYear] = useState(
    userData && userData.stripe && userData.stripe.exp_year
  );
  const [cvc, setCVC] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const { data } = await myAxios.post(
        `/api/subscribe/${localStorage.getItem("vmid")}`,
        {
          card_number: cardNumber,
          card_month: expMonth,
          card_year: expYear,
          card_cvc: cvc,
          plan: planlocal,
        }
      );
      success(data);
      getUser();
      setLoading(false);
    } catch (error) {
      refuse(error.response.data.join(","));
      setLoading(false);
    }
  };

  return (
    <div className="package-modal">
      <div className="payment-modal-container-inner">
        <div className="modal-content">
          <div className="modal-body">
            <div className="modal-close" onClick={() => setPackageModal(false)}>
              <CloseIcon />
            </div>

            <div className="modal-body-title">
              <div className="modal-body-title-left">
                <h4>
                  Enter <span>credit card</span> below.
                </h4>
              </div>
              <div className="modal-body-title-right">
                ${planlocal == "Monthly" ? 1 : 10}
              </div>
            </div>

            <form className="paymentFrm" id="paymentFrm">
              <div className="form-group">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  required="required"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <input
                  type="hidden"
                  name="email"
                  className="form-control"
                  placeholder="email@you.com"
                  required
                />
              </div>
              <div className="form-group">
                <label>Card Number</label>
                <input
                  type="text"
                  className="form-control"
                  name="card_number"
                  id="card_num"
                  autoComplete="off"
                  required="required"
                  value={cardNumber}
                  onChange={(e) => setCardNumber(e.target.value)}
                />
              </div>
              <div className="form-group form-group-container">
                <div className="col-xs-4">
                  <label>Expiration Month</label>
                  <input
                    type="text"
                    className="form-control"
                    name="card_month"
                    placeholder="MM"
                    required="required"
                    id="card-expiry-month"
                    value={expMonth}
                    onChange={(e) => setExpMonth(e.target.value)}
                  />
                </div>
                <div className="col-xs-4">
                  <label>Expiration Year</label>
                  <input
                    type="text"
                    className="form-control"
                    name="card_year"
                    id="card-expiry-year"
                    required="required"
                    placeholder="YY"
                    value={expYear}
                    onChange={(e) => setExpYear(e.target.value)}
                  />
                </div>
                <div className="col-xs-4">
                  <label>CVC</label>
                  <input
                    type="text"
                    name="card_cvc"
                    id="card-cvc"
                    maxLength="3"
                    className="form-control"
                    autoComplete="off"
                    placeholder="CVC"
                    required="required"
                    value={cvc}
                    onChange={(e) => setCVC(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Phone Number</label>
                <input
                  type="text"
                  className="form-control"
                  required="required"
                  name="phone_number"
                  phoneNumber={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label className="promo"></label>
              </div>
              <div className="form-group">
                <button
                  type="submit"
                  id="payBtn"
                  className="payment-success-button"
                  onClick={handleSubmit}
                >
                  {loading ? "Loading" : "Pay Now!"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageModal;
