import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { LoginContext } from "../../contexts/LogingProvider";
import { toast, ToastContainer } from "react-toastify";
import myAxiosGlobal from "../MyAxios/MyAxiosGlobal";
import LogoImage from "../../images/emailverifier.png";

import "../css/Login.css";

const PasswordForgot = () => {
  const { handleLogin, error, verifyToken } = useContext(LoginContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = `Forgot Password | Free Email Verifier`;
  }, []);

  const handleLoginForm = (e) => {
    e.preventDefault();
    setLoading(true);
    myAxiosGlobal
      .post("/api/forgotpassword", { email: email })
      .then((response) => {
        console.log(response.data);
        toast.success(`Password reset email has been sent to ${email}`);
        setEmail("");
        setLoading(false);
      })
      .catch((error) => {
        console.log(error?.response?.data);
        toast.error(error.response.data);
        setLoading(false);
      });
  };

  return (
    <div className="login-main-container">
      <ToastContainer />
      <form className="login-container" onSubmit={handleLoginForm}>
        <img src={LogoImage} alt="stripe-image" className="login-logo" />
        <div className="login-error">
          <p>{error ? error : null}</p>
        </div>
        <div className="login-card">
          <input
            type="text"
            placeholder="Enter Your Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <button className="login-btn">
            {loading ? "Loding..." : "FORGOT PASSWORD"}
          </button>
          <p className="forgot-text">
            <Link to="/login">Login</Link>
          </p>
        </div>
      </form>
    </div>
  );
};

export default PasswordForgot;
