import React from "react";
import { LanguageList } from "../Data/LanguageList";

const LanguageSelect = (props) => {
  return (
    <select name={props.name} key={props.name} onChange={props.onChange}>
      {LanguageList.map((lanuage) => (
        <option key={props.name} value={props.value}>
          {lanuage}
        </option>
      ))}
    </select>
  );
};

export default LanguageSelect;
