import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import "./CreateProject.css";

import { MdEmail } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";

//downloadPdf
import { CSVLink } from "react-csv";

const ProjectDetails = () => {
  const location = useLocation();
  const history = useHistory();
  const id = localStorage.getItem("vmid");
  const { state } = location;

  let emailsSuccessData = [];
  let emailsFailData = [];

  const [falseEmail, setFalseEmail] = useState(0);
  const [trueEmail, setTrueEmail] = useState(0);
  const [totalEmail, setTotalEmail] = useState([]);

  totalEmail.map((email) => {
    if (email.result.is_verified === true) {
      emailsSuccessData.push({
        Email: email.email,
        Status: "Success",
      });
    }

    if (email.result.is_verified === false) {
      emailsFailData.push({
        Email: email.email,
        Status: "Failure",
      });
    }
  });

  useEffect(() => {
    document.title = `Bulk Email Details | Free Email Verifier`;

    if (!state || state === undefined || state === null) {
      return history.push(`/account/bulk-verifier/${id}`);
    }

    setFalseEmail(state.isVerifiedFalse);
    setTrueEmail(state.isVerifiedTrue);
    setTotalEmail(state.verifyResult);
  }, []);
  return (
    <div className="project-details">
      <div className="projects-row bulk-email-row">
        <div className="project-card">
          <div className="project-icon">
            <FaCheckCircle className="icon" />
          </div>
          <div className="project-num">{trueEmail}</div>
          <div className="project-text">Valid Emails</div>
        </div>
        <div className="project-card">
          <div className="project-icon">
            <MdEmail className="icon" />
          </div>
          <div className="project-num">{falseEmail}</div>
          <div className="project-text">Invalid Emails</div>
        </div>
      </div>
      <div className="table-header">
        <div className="table-header-left">
          {emailsSuccessData && emailsSuccessData.length > 0 && (
            <CSVLink
              data={emailsSuccessData}
              className="email-create-btn"
              style={{ textDecoration: "none" }}
            >
              Download Success Csv
            </CSVLink>
          )}
        </div>
        <div
          className="table-header-right"
          style={{
            textDecoration: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          {emailsFailData && emailsFailData.length > 0 && (
            <CSVLink
              data={emailsFailData}
              className="email-create-btn failsEmail"
              style={{ textDecoration: "none", backgroundColor: "red" }}
            >
              Download Failure Csv
            </CSVLink>
          )}
        </div>
      </div>

      <table id="customers" className="email-project-table">
        <thead>
          <tr>
            <th>Email</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {totalEmail &&
            totalEmail.length &&
            totalEmail.map((name, index) => {
              return (
                <tr
                  key={index}
                  className={`${
                    name.result.is_verified ? "green_row" : "red_row"
                  }`}
                >
                  <td>{name.email}</td>
                  <td>
                    {name.result.is_verified === false ? "Invalid" : "Valid"}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default ProjectDetails;
