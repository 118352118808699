import axios from "axios";

let token = localStorage.getItem("vmtoken");
let email = localStorage.getItem("vmuser");

var myAxiosM = axios.create({
  baseURL: "https://verrifyapi.ecomtechbd.com",
  // baseURL: "https://vapi.ecomtechbd.com",
  headers: { token: token, email: email },
});

export default myAxiosM;
