import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { EmailContext } from "../../contexts/EmailProvider";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { FaLock } from "react-icons/fa";
import { FcApproval } from "react-icons/fc";
import myAxios from "../MyAxios/MyAxios";

import "./SignupModal.css";

function ThankuModal() {
  const history = useHistory();
  const { thankuModal, setThankuModal } = useContext(EmailContext);

  //local state
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [required, setRequired] = useState(false);
  const [viewEmail, setViewEmail] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    myAxios
      .post("/api/change-email", {
        id: localStorage.getItem("vmid"),
        email: email,
      })
      .then((res) => {
        toast.success("Email Changed Successfully");
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data);
        setLoading(false);
      });
  };

  return (
    <>
      <ToastContainer />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={thankuModal}
        onClose={() => setThankuModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        style={{ overflow: "scroll" }}
      >
        <Fade in={thankuModal} className="payment-modal-container">
          <div className="payment-modal-container-inner">
            <div className="modal-content">
              <div className="modal-header-signup">
                <button
                  type="button"
                  className="close"
                  onClick={() => setThankuModal(false)}
                >
                  &times;
                </button>
                <p className="payment-top text-center">
                  A LINK HAS BEEN SEND TO YOUR EMAIL, PLEASE CLICK THE LINK TO
                  VERIFY FREE MAIL EMAIL AGAIN
                </p>
                <div id="payment-errors"></div>
              </div>
              <div className="modal-body">
                <div className="thanku-content">
                  <FcApproval
                    style={{
                      marginBottom: "20px",
                      fontSize: "60px",
                    }}
                  />
                  <h4>THANK YOU FOR SIGN UP!</h4>
                  <p>
                    A LINK HAS BEEN SEND TO YOUR EMAIL, PLEASE CLICK THE LINK TO
                    VERIFY FREE MAIL EMAIL AGAIN
                  </p>
                </div>
                <form className="paymentFrm">
                  {viewEmail ? (
                    <>
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="Email"
                          className={
                            email === "" && required
                              ? "form-control red-error-border"
                              : "form-control"
                          }
                          name="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <button
                          type="submit"
                          className={
                            loading
                              ? "payment-success-button disabled"
                              : "payment-success-button"
                          }
                          onClick={handleSubmit}
                        >
                          {loading ? "Loading.." : "change email!"}
                        </button>
                      </div>
                    </>
                  ) : (
                    <div className="form-group">
                      <button
                        type="submit"
                        className={
                          loading
                            ? "payment-success-button-signup disabled"
                            : "payment-success-button-signup"
                        }
                        onClick={() => {
                          setViewEmail(true);
                        }}
                      >
                        {loading ? "Loading.." : "want to change email?"}
                      </button>
                    </div>
                  )}
                </form>
              </div>

              <div className="payment-footer-signup">
                <div className="payment">
                  <FaLock className="lock-icon" />{" "}
                  <h5>
                    100% Free Email Verifier <span></span>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}

export default ThankuModal;
