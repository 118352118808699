import React, { createContext, useState } from "react";

export const ToggleContext = createContext();

const ToggleProvider = (props) => {
  const [toggleMobile, setToggleMobile] = useState(false);
  const [addCreditModalUpdate, setAddCreditModalUpdate] = useState(false);
  const [notification, setNotification] = useState(false);
  const [popUpSocial, setPopUpSocial] = useState(false);
  const [popUpUser, setPopUpUser] = useState(false);
  const [notificationSearch, setNotificationSearch] = useState(false);
  const [activeColorf, setActiveColorf] = useState(false);
  const [activeColors, setActiveColors] = useState(false);
  const [packageModal, setPackageModal] = useState(false);
  const [sendingDomainModal, setSendingDomainModal] = useState(false);
  const [token, setToken] = useState(true);

  return (
    <ToggleContext.Provider
      value={{
        toggleMobile,
        setToggleMobile,
        notification,
        setNotification,
        popUpSocial,
        setPopUpSocial,
        popUpUser,
        setPopUpUser,
        notificationSearch,
        setNotificationSearch,
        activeColorf,
        setActiveColorf,
        activeColors,
        setActiveColors,
        packageModal,
        setPackageModal,
        sendingDomainModal,
        setSendingDomainModal,
        token,
        setToken,
        addCreditModalUpdate,
        setAddCreditModalUpdate,
      }}
    >
      {props.children}
    </ToggleContext.Provider>
  );
};

export default ToggleProvider;
