import React from "react";
import "./ServicesSection.css";

import ServiceImage from "../../images/service_image.png";
import Customisable from "../../images/customisable.png";
import UiElement from "../../images/ui_element.png";
import Documented from "../../images/documented.png";
import FreeUpdate from "../../images/free_update.png";
const ServicesSection = () => {
  return (
    <section className="service-section-container">
      <div className="service-section-main-content">
        <div className="service-section-content">
          <div className="service-section-title">Email Verifier.</div>
          <div className="service-section-title">Easy, Fast & Cheap</div>
          <div className="service-section-text">
            Our Email Verifier can be performed for a list of email addresses or
            one by one copy pest. Create your project Upload csv file or Copy
            pest email on the dashboard and wait for validations.
          </div>
        </div>
        <div className="service-section-img">
          <img src={ServiceImage} alt="service_image" />
        </div>
      </div>
      <div className="service-section-services">
        <div className="services-row">
          <div className="service-item">
            <img src={Customisable} alt="customisable" />
            <div className="services-row-title">BULK EMAIL VERIFIER</div>
            <div className="services-row-text">
              Our bulk email validation tool Free | Bulk Email Verifier is an
              online service
            </div>
          </div>
          <div className="service-item">
            <img src={UiElement} alt="customisable" />
            <div className="services-row-title">
              Auto Weekly Email Verification
            </div>
            <div className="services-row-text">
              You can set automatic Bulk Email Verification for Your list every
              week
            </div>
          </div>
        </div>
        <div className="services-row">
          <div className="service-item">
            <img src={Documented} alt="customisable" />
            <div className="services-row-title">CHEAP BULK EMAIL CHECKER</div>
            <div className="services-row-text">
              Cheap and the most accurate bulk email checker. Complete email
              address verification in seconds
            </div>
          </div>
          <div className="service-item">
            <img src={FreeUpdate} alt="customisable" />
            <div className="services-row-title">FREE EMAIL REPORT</div>
            <div className="services-row-text">
              You will get free automatic Bulk Email Verification report in your
              email and Dashboard
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
