import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../contexts/UserProvider";
import myAxios from "../MyAxios/MyAxios";

import "../css/DashboardMainContent.css";

//icons

import { GiClick } from "react-icons/gi";
import { AiFillProject } from "react-icons/ai";
import { MdQueue } from "react-icons/md";
import { HiOutlineMailOpen } from "react-icons/hi";
import { GiBouncingSpring } from "react-icons/gi";
import { AiFillCreditCard } from "react-icons/ai";
import { RiAccountBoxFill } from "react-icons/ri";
import { FaCalendarAlt } from "react-icons/fa";
import { CgPlayListRemove } from "react-icons/cg";
import NotificationStepper from "./NotificationStepper";
import { MdSmsFailed } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";

function DashboardMainContent({ amount }) {
  const history = useHistory();
  const [dailyBmessase, setDailyBmessase] = useState(0);
  const {
    emailCredit,
    packageName,
    getEmailData,
    demail,
    getUser,
    getDomainList,
  } = useContext(UserContext);
  const {
    d_bounce,
    d_click,
    d_open,
    d_queue_count,
    d_totalCount,
    d_unsubscribe,
  } = demail;

  useEffect(() => {
    document.title = `Dashboard | Free Email Verifier`;
    getEmailData();
  }, []);

  useEffect(() => {
    getUser();
    getDomainList();
  }, []);

  useEffect(async () => {
    await myAxios
      .get(`/api/dailybmessage/${localStorage.getItem("vmid")}`)
      .then(({ data }) => {
        setDailyBmessase(data.CountDailyMessage);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="dashboard-main-content-box">
      <div className="projects">
        <div className="projects-row">
          <div className="project-card">
            <div className="project-icon">
              <AiFillProject className="icon" />
            </div>
            <div className="project-num">
              {(d_totalCount && d_totalCount) || 0}
            </div>
            <div className="project-text">Total Projects</div>
          </div>
          <div className="project-card">
            <div className="project-icon">
              <FaCheckCircle className="icon" />
            </div>
            <div className="project-num">{(d_open && d_open) || 0}%</div>
            <div className="project-text">Total Verified</div>
          </div>
          <div className="project-card">
            <div className="project-icon">
              <MdSmsFailed className="icon" />
            </div>
            <div className="project-num">{(d_click && d_click) || 0}</div>
            <div className="project-text">Total Failed</div>
          </div>
        </div>
        {/* <div className="projects-row">
          <div
            className="project-card"
            style={{ cursor: "pointer" }}
            onClick={() => {
              history.push("/account/bounce-message");
            }}
          >
            <div className="project-icon">
              <GiBouncingSpring className="icon" />
            </div>
            <div className="project-num">{dailyBmessase && dailyBmessase}</div>
            <div className="project-text">Bounce Today</div>
          </div>
          <div className="project-card">
            <div className="project-icon">
              <CgPlayListRemove className="icon" />
            </div>
            <div className="project-num">{d_unsubscribe && d_unsubscribe}</div>
            <div className="project-text">Today Unsubscribe</div>
          </div>
          <div className="project-card">
            <div className="project-icon">
              <MdQueue className="icon" />
            </div>
            <div className="project-num">{d_queue_count && d_queue_count}</div>
            <div className="project-text">Warmup Queue</div>
          </div>
        </div> */}
      </div>

      <section className="current-status">
        <div className="current-status-row">
          <div className="status-card">
            <div className="status-logo">
              <AiFillCreditCard className="logo" />
            </div>
            <div className="status-text">
              <h4>{(amount && amount.credit) || 0}</h4>
              <p>Account Credits</p>
            </div>
          </div>
          <div className="status-card">
            <div className="status-logo">
              <AiFillCreditCard className="logo" />
            </div>
            <div className="status-text">
              <h4>{(amount && amount.freeCredit) || 0}</h4>
              <p>Free Credits</p>
            </div>
          </div>
          <div className="status-card">
            <div className="status-logo">
              <RiAccountBoxFill className="logo" />
            </div>
            <div className="status-text">
              <h4>Prepaid</h4>
              <p>Account Type</p>
            </div>
          </div>
          {/* <div className="status-card">
            <div className="status-logo">
              <FaCalendarAlt className="logo" />
            </div>
            <div className="status-text">
              <h4>{packageName === "Monthly" ? "1$ Month" : "10$ Year"}</h4>
              <p>Subscription</p>
            </div>
          </div> */}
        </div>
      </section>
    </div>
  );
}

export default DashboardMainContent;
