import React from "react";
import "./PreFooter.css";

import Gabo from "../../images/gabo.png";
import Inner from "../../images/inner.png";
import RealWave from "../../images/real_wave.png";
import Wire from "../../images/wire.png";

const PreFooter = () => {
  return (
    <section className="pre-footer-container">
      <div className="pre-footer-left">
        <div className="pre-footer-column">
          <img src={Gabo} alt="Gabo" />
        </div>
        <div className="pre-footer-column">
          <img src={Inner} alt="Inner" />
        </div>
      </div>
      <div className="pre-footer-right">
        <div className="pre-footer-column">
          <img src={RealWave} alt="RealWave" />
        </div>
        <div className="pre-footer-column">
          <img src={Wire} alt="Wire" />
        </div>
      </div>
    </section>
  );
};

export default PreFooter;
