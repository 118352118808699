import React, { createContext, useState } from "react";
import myAxios from "../componends/MyAxios/MyAxios";

export const EmailContext = createContext();

const EmailProvider = ({ children }) => {
  const [projectName, setProjectName] = useState("");
  const [email, setEmail] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [thankuModal, setThankuModal] = useState(false);
  const [signin, setSignin] = useState(false);

  return (
    <EmailContext.Provider
      value={{
        projectName,
        setProjectName,
        email,
        setEmail,
        isOpen,
        setIsOpen,
        thankuModal,
        setThankuModal,
        signin,
        setSignin,
      }}
    >
      {children}
    </EmailContext.Provider>
  );
};

export default EmailProvider;
