import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

function Alert() {
  //   const [open, setOpen] = React.useState(false);

  //   const handleClose = () => {
  //     setOpen(false);
  //   };

  //   return (
  //     <div>
  //       <Dialog
  //         open={open}
  //         onClose={handleClose}
  //         aria-labelledby="alert-dialog-title"
  //         aria-describedby="alert-dialog-description"
  //       >
  //         <DialogTitle id="alert-dialog-title">
  //           {"Are You sure want to cancel ?"}
  //         </DialogTitle>
  //         <DialogContent>
  //           <DialogContentText id="alert-dialog-description">
  //             Let Google help apps determine location. This means sending
  //             anonymous location data to Google, even when no apps are running.
  //           </DialogContentText>
  //         </DialogContent>
  //         <DialogActions>
  //           <Button onClick={handleClose} color="primary">
  //             Yes
  //           </Button>
  //           <Button onClick={handleClose} color="primary" autoFocus>
  //             No
  //           </Button>
  //         </DialogActions>
  //       </Dialog>
  //     </div>
  //   );

  return <h1>alert</h1>;
}

export default Alert;
