import React from "react";
import DashboardFooter from "./DashboardFooter";
import DashboarMain from "./DashboardMain";
import DashboardSideBar from "./DashboardSideBar";
import "../css/Dashboard.css";

function Dashboard({ amount }) {
  return (
    <div className="dashboard-main-container">
      <div className="Dashboard-main-content">
        <DashboardSideBar />
        <DashboarMain amount={amount} />
      </div>
      <DashboardFooter />
    </div>
  );
}

export default Dashboard;
