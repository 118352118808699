import React, { createContext, useState } from "react";
import myAxios from "../componends/MyAxios/MyAxios";

export const UserContext = createContext();

const UserProvider = (props) => {
  const [email, setEmail] = useState("");
  const [emailCredit, setEmailCredit] = useState("");
  const [packageName, setPackageName] = useState("");
  const [validity, setValidity] = useState("");
  const [loading, setLoading] = useState(false);
  const [demail, setDemail] = useState("");
  const [userData, setUserData] = useState("");
  const [domainList, setDomainList] = useState("");
  const [plan, setPlan] = useState("");
  const [token, setToken] = useState("");
  const [notificationCount, setNotificationCount] = useState([]);
  const [activate, setActivate] = useState(false);

  const getUser = async () => {
    setLoading(true);
    await myAxios
      .get(`/api/userprofile/${localStorage.getItem("vmid")}`)
      .then((response) => {
        // setValidity(response.data.stripe.validity);
        // setEmail(response.data.email);
        // setEmailCredit(response.data.email_credit);
        // setPackageName(response.data.stripe.plan);
        setUserData(response.data);
        // setActivate(response.data.activate);
        // if (response.data.activate === false) {
        //   setNotificationCount([...notificationCount, "activate"]);
        // }
        // setPlan(response.data.stripe.plan);
        // setToken(response.data.stripe.token);
        // if (!response.data.stripe.token) {
        //   setNotificationCount([...notificationCount, "token"]);
        // }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const getEmailData = async () => {
    setLoading(true);
    await myAxios
      .get(`/api/email/${localStorage.getItem("vmid")}`)
      .then((response) => {
        setDemail(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const getDomainData = async () => {
    setLoading(true);
    await myAxios
      .get(`/api/domain/${localStorage.getItem("vmid")}`)
      .then((response) => {
        setDemail(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getDomainList = async () => {
    setLoading(true);
    await myAxios
      .get(`/api/domain/list/${localStorage.getItem("vmid")}`)
      .then((response) => {
        setDomainList(response.data);
        if (response.data.length === 0) {
          setNotificationCount([...notificationCount, "domain"]);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <UserContext.Provider
      value={{
        email,
        emailCredit,
        packageName,
        validity,
        getUser,
        getEmailData,
        demail,
        loading,
        setLoading,
        getDomainData,
        getDomainList,
        domainList,
        userData,
        plan,
        token,
        notificationCount,
        setNotificationCount,
        activate,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserProvider;
