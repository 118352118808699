import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import ReactLoading from "react-loading";
import { toast, ToastContainer } from "react-toastify";
import MyAxiosM from "../MyAxios/MyAxiosM";
import "./Banner.css";

import MessageImage from "../../images/email-2.webp";
//context
import { EmailContext } from "../../contexts/EmailProvider";
//config file
require("dotenv").config();

const Banner = () => {
  let history = useHistory();
  const [loading, setLoading] = useState("");
  const [verified, setVerified] = useState(false);
  const { email, setEmail, isOpen, setIsOpen, setSignin } =
    useContext(EmailContext);
  const onChange = () => {
    setLoading("loading");
    MyAxiosM.post("/email-verify1", { email })
      .then((response) => {
        setLoading("");
        history.push("/validemail", { data: response.data });
        if (response.data.is_verified) {
          var count = localStorage.getItem("countVerify");
          let countNumber = parseInt(count);
          count = countNumber + 1;
          localStorage.setItem("countVerify", count);
        }
      })
      .catch((error) => setLoading(""));
  };
  const handleEmail = (e) => {
    e.preventDefault();
    if (!email) {
      toast.error("Please enter your email address");
      return;
    }
    if (parseInt(localStorage.getItem("signupemailsend")) === 1) {
      onChange();
      setVerified(false);
      return;
    }
    if (
      // localStorage.getItem("isSignup") == "false" &&
      parseInt(localStorage.getItem("countVerify")) === 1
    ) {
      setIsOpen(true);
      setVerified(false);
      return;
    } else {
      setVerified(true);
    }
  };

  return (
    <section className="banner">
      <ToastContainer />
      <div className="email-heading-content">
        <h1 className="email-verifier-title">Email Verifier</h1>
        <h2 className="email-verifier-subtitle">
          100% Free Email Verifier Tools | Free Email Checker & Email
          Verification Tool Online | Bulk Email Verifier | Email Verifier Api |
          1 Click To Verify
        </h2>
      </div>
      <div
        className={
          parseInt(localStorage.getItem("signupemailsend")) != 0
            ? "banner-container-form extra-padding"
            : "banner-container-form"
        }
      >
        {verified && loading === "" && (
          <ReCAPTCHA
            className="rechapcha-container"
            sitekey={process.env.REACT_APP_SITE_KEY}
            onChange={onChange}
          />
        )}
        {loading === "loading" && (
          <ReactLoading
            type={"spin"}
            color={"#8834fd"}
            height={200}
            width={200}
            delay={20}
          />
        )}
        {parseInt(localStorage.getItem("signupemailsend")) === 0 && (
          <div
            style={{
              textAlign: "center",
              fontSize: "24px",
              color: "red",
              marginBottom: "15px",
            }}
          >
            You have signed up successfully. Now verify your email. If already
            verified?{" "}
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => {
                setIsOpen(true);
                setSignin(true);
              }}
            >
              Click Here
            </span>{" "}
            to login and disable ReCAPTCHA.
          </div>
        )}

        {loading === "" && (
          <form onSubmit={handleEmail}>
            <input
              type="text"
              name="email"
              placeholder="ENTER EMAIL TO VERIFY"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button
              type="submit"
              className={
                verified
                  ? "banner-container-form-button disbaled-form-button"
                  : "banner-container-form-button"
              }
            >
              VERIFY
            </button>
          </form>
        )}
      </div>

      <h2
        className="bulk-verify"
        onClick={() => {
          if (localStorage.getItem("vmtoken")) {
            history.push("/account/bulk-verifier");
          } else {
            history.push("/login", { state: "bulk" });
          }
        }}
      >
        Bulk Verify Only $0.002
      </h2>
    </section>
  );
};

export default Banner;
