import React, { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { AiOutlineCloudUpload } from "react-icons/ai";
import "./CreateProject.css";

const UploadCsvModal = ({ setUploadCsvModal }) => {
  const [csvfile, setCsvFile] = useState(null);
  const [csvArray, setCsvArray] = useState([]);

  const processCsv = (text) => {
    const data = text.slice(0, text.indexOf("\n")).split(",");
    const rows = text.slice(text.indexOf("\n") + 1).split("\n");
    const newArray = rows.map((row) => {
      const values = row.split(",");
      const eachObject = data.reduce((obj, data, i) => {
        obj[data] = values[i];
        return obj;
      }, {});
      return eachObject;
    });
    setCsvArray(newArray);
  };
  const submit = (e) => {
    e.preventDefault();
    if (!csvfile) return;
    const reader = new FileReader();
    reader.onload = (e) => {
      const csv = e.target.result;
      processCsv(csv);
    };
    reader.readAsText(csvfile);
  };

  console.log(csvArray);
  return (
    <div className="create-project-modal-container">
      <div className="modal-close" onClick={() => setUploadCsvModal(false)}>
        <CloseIcon />
      </div>
      <form id="csv-form">
        <input
          type="file"
          accept=".csv"
          id="csv-file"
          onChange={(e) => setCsvFile(e.target.files[0])}
          hidden
        />
        <label htmlFor="csv-file">
          <div className="csv-file-upload">
            <div className="csv-drop-file">
              <AiOutlineCloudUpload className="csv-upload-icon" />
              <h4>Drag and Drop File Here</h4>
            </div>
            <div className="csv-or">OR</div>
            <input
              type="file"
              accept=".csv"
              id="csv-file"
              onChange={(e) => setCsvFile(e.target.files[0])}
            />
          </div>
        </label>
        <button
          type="submit"
          id="csv-submit"
          className="email-create-btn"
          style={{ marginTop: "10px" }}
          onClick={submit}
        >
          Submit
        </button>
      </form>
      <h3>Csv file data</h3>
      {csvArray.slice(0, 10).map((row, i) => {
        return (
          <div key={i}>
            <h4>Person {row.EMPLOYEE_ID}</h4>
            <p>firsName: {row.FIRST_NAME}</p>
            <p>lastName: {row.LAST_NAME}</p>
            <p>email: {row.EMAIL}</p>
          </div>
        );
      })}
    </div>
  );
};

export default UploadCsvModal;
