import React, { useContext } from "react";
import { MdContactMail } from "react-icons/md";
import { GoVerified } from "react-icons/go";
import { BiCartAlt } from "react-icons/bi";
import { BiKey } from "react-icons/bi";
import { MdCreateNewFolder } from "react-icons/md";
import { FcHome } from "react-icons/fc";
import { GrServerCluster } from "react-icons/gr";

import "../css/DashboardSideBar.css";
import { Link } from "react-router-dom";
import { ToggleContext } from "../../contexts/ToggleProvider";
import LogoImage from "../../images/emailverifier.png";

function DashboardSideBar() {
  const { toggleMobile, setToggleMobile } = useContext(ToggleContext);
  const id = localStorage.getItem("vmid");
  return (
    <div className="dashboard-sidebar">
      <Link to="/dashboard">
        <div className="dashboard-sidebar-header">
          <img src={LogoImage} />
        </div>
      </Link>
      <div
        className={
          toggleMobile
            ? "dashboard-sidebar-content active"
            : "dashboard-sidebar-content"
        }
      >
        <div className="dashboard-sidebar-menu">
          <Link to="/dashboard" onClick={() => setToggleMobile(false)}>
            <div className="dashboard-sidebar-menu-item">
              <FcHome className="side-icon" />
              <span>Dashboard</span>
            </div>
          </Link>

          <Link
            to={`/account/bulk-verifier`}
            onClick={() => setToggleMobile(false)}
          >
            <div className="dashboard-sidebar-menu-item">
              <MdContactMail className="side-icon" />
              <span>Bulk Verifier</span>
            </div>
          </Link>

          {/* <Link to="/create-project" onClick={() => setToggleMobile(false)}>
            <div className="dashboard-sidebar-menu-item">
              <MdCreateNewFolder className="side-icon" />
              <span>Create Project</span>
            </div>
          </Link> */}
          <Link to="/" onClick={() => setToggleMobile(false)}>
            <div className="dashboard-sidebar-menu-item">
              <GoVerified className="side-icon" />
              <span>Free EmailVerifier</span>
            </div>
          </Link>
          <Link to="/account/api" onClick={() => setToggleMobile(false)}>
            <div className="dashboard-sidebar-menu-item">
              <BiKey className="side-icon" />
              <span>EmailVerifier API</span>
            </div>
          </Link>
          <a
            href="https://queensmtp.com"
            target="blank"
            onClick={() => setToggleMobile(false)}
          >
            <div className="dashboard-sidebar-menu-item">
              <GrServerCluster className="side-icon" />
              <span>SMTP Service</span>
            </div>
          </a>
          <Link to="/billing" onClick={() => setToggleMobile(false)}>
            <div className="dashboard-sidebar-menu-item">
              <BiCartAlt className="side-icon" />
              <span>Account Billing</span>
            </div>
          </Link>
          <Link to="/contact" onClick={() => setToggleMobile(false)}>
            <div className="dashboard-sidebar-menu-item">
              <MdContactMail className="side-icon" />
              <span>Contact Us</span>
            </div>
          </Link>

          {/* <Link to="/sending_domain" onClick={() => setToggleMobile(false)}>
            <div className="dashboard-sidebar-menu-item">
              <PublicIcon className="side-icon" />
              <span>Sending Domains</span>
            </div>
          </Link>
          <Link to="/account/emails" onClick={() => setToggleMobile(false)}>
            <div className="dashboard-sidebar-menu-item">
              <BookIcon className="side-icon" />
              <span>Email Logs</span>
            </div>
          </Link>
          <Link to="/account/api" onClick={() => setToggleMobile(false)}>
            <div className="dashboard-sidebar-menu-item">
              <VpnKeyIcon className="side-icon" />
              <span>Api & Smtp</span>
            </div>
          </Link>
          <Link
            to="/account/bounce-message"
            onClick={() => setToggleMobile(false)}
          >
            <div className="dashboard-sidebar-menu-item">
              <GiBouncingSpring className="side-icon" />
              <span>Bounce Message</span>
            </div>
          </Link>*/}
        </div>
        <div className="dashboard-sidebar-help">
          <p className="help-content">Need Help?</p>
          <p className="help-content">+1(929)312-3***</p>
          <p className="help-content">
            <a href="mailto:support@QUEENSMTP">support@FreeEmailVerifier</a>
          </p>
          <p className="help-content">
            {/* Visit <Link to="/faqs">FAQ</Link> */}
          </p>
        </div>
      </div>
    </div>
  );
}

export default DashboardSideBar;
