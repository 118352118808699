import React, { useEffect, useState, useContext } from "react";
import ContactInfo from "../profile/ContactInfo";
import DashboardMainNav from "./DashboardMainNav";
import DashboardMainContent from "./DashboardMainContent";
import CreateProject from "../EmailsProject/CreateProject";
import ProjectDetails from "../EmailsProject/ProjectDetails";
import BulkVerifier from "../EmailsProject/BulkVerifier";
import BulkVerifierDetails from "../EmailsProject/BulkVerifierDetails";
import DashboardNotification from "./DashboardNotification";
import NotificationStepper from "./NotificationStepper";
import Billing from "./Billing";
import { Route, Switch } from "react-router-dom";
import "../css/DashboardMain.css";
import SendingDomain from "../Domains/SendingDomain";
import SingleDomain from "../Domains/SingleDomain";
import MyProfile from "../profile/MyProfile";
import Api from "../profile/Api";
import Emails from "../profile/Emails";
import Thanks from "./Thanks";
import Faqs from "./Faqs";
import PageNotFound from "./404";
import { ProtectedRoute } from "../ProtectedRoute/ProtectedRoute";
import { UserContext } from "../../contexts/UserProvider";
import { LinearProgress } from "@material-ui/core";
import SubscriptionsNotificationDashboard from "./SubscriptionsNotificationDashboard";
import BounceMessage from "./BounceMessage";

function DashboardMain({ toggleMobile, setToggleMobile, amount }) {
  const { loading, userData, domainList, token } = useContext(UserContext);
  const [toggleNotification, setToggleNotification] = useState(false);

  return (
    <div className="dashboard-main">
      <DashboardMainNav
        toggleMobile={toggleMobile}
        setToggleMobile={setToggleMobile}
        amount={amount}
      />
      <div>
        <div className="loading-progress">
          {loading && (
            <LinearProgress value={100} valueBuffer={10} progress={10} />
          )}
        </div>

        {/* <div className="subscriptions-notification-dashboard ">
          <SubscriptionsNotificationDashboard
            token={token}
            userData={userData}
            domainList={domainList}
            setToggleNotification={setToggleNotification}
            toggleNotification={toggleNotification}
          />
        </div> */}

        <div className={`dashboard-main-box-container body-container `}>
          <Switch>
            <ProtectedRoute exact path="/dashboard">
              <DashboardMainContent amount={amount} />
            </ProtectedRoute>
            <ProtectedRoute path="/billing">
              <Billing amount={amount} />
            </ProtectedRoute>
            <ProtectedRoute exact path="/sending_domain">
              <SendingDomain />
            </ProtectedRoute>
            <ProtectedRoute exact path="/create-project">
              <CreateProject />
            </ProtectedRoute>
            <ProtectedRoute exact path="/create-project/details">
              <ProjectDetails />
            </ProtectedRoute>
            <ProtectedRoute exact path="/sending_domain/view/:id">
              <SingleDomain />
            </ProtectedRoute>
            <ProtectedRoute exact path="/account/contact">
              <ContactInfo />
            </ProtectedRoute>
            <ProtectedRoute exact path="/account/profile">
              <MyProfile />
            </ProtectedRoute>
            <ProtectedRoute exact path="/account/api">
              <Api />
            </ProtectedRoute>
            <ProtectedRoute exact path="/account/emails">
              <Emails />
            </ProtectedRoute>
            <ProtectedRoute exact path="/account/bounce-message">
              <BounceMessage />
            </ProtectedRoute>
            <ProtectedRoute exact path="/account/bulk-verifier/details/:id">
              <BulkVerifierDetails />
            </ProtectedRoute>
            <ProtectedRoute exact path="/account/bulk-verifier">
              <BulkVerifier />
            </ProtectedRoute>

            <ProtectedRoute exact path="/thanks">
              <Thanks />
            </ProtectedRoute>
            <ProtectedRoute exact path="/faqs">
              <Faqs />
            </ProtectedRoute>
            <Route path="*">
              <PageNotFound />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default DashboardMain;
