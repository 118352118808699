import React, { useEffect } from "react";
import "./CreateProject.css";

import { MdSmsFailed } from "react-icons/md";
import { MdEmail } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";

const ProjectDetails = () => {
  useEffect(() => {
    document.title = `Project Details | Free Email Verifier`;
  }, []);
  return (
    <div className="project-details">
      <div className="projects-row">
        <div className="project-card">
          <div className="project-icon">
            <MdEmail className="icon" />
          </div>
          <div className="project-num">1234</div>
          <div className="project-text">Emails In Project</div>
        </div>
        <div className="project-card">
          <div className="project-icon">
            <FaCheckCircle className="icon" />
          </div>
          <div className="project-num">80%</div>
          <div className="project-text">Verified Email</div>
        </div>
        <div className="project-card">
          <div className="project-icon">
            <MdSmsFailed className="icon" />
          </div>
          <div className="project-num">1000</div>
          <div className="project-text">Failed Verification</div>
        </div>
      </div>
      <div className="table-header">
        <div className="table-header-left">
          <button className="email-create-btn">Verify Emails</button>
        </div>
        <div
          className="table-header-right"
          style={{ display: "flex", justifyContent: "end", marginRight: "0px" }}
        >
          <button className="email-create-btn">Download Csv</button>
        </div>
      </div>
      <table id="customers" className="email-project-table">
        <thead>
          <tr>
            <th>Email</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>shamiulislam388@gmail.com</td>
            <td>success</td>
          </tr>
          <tr>
            <td>shamiulislam388@gmail.com</td>
            <td>failed</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ProjectDetails;
