import React, { useEffect } from "react";
import "../css/Thanks.css";
import CheckIcon from "@material-ui/icons/Check";
import { Link } from "react-router-dom";

function Thanks() {
  useEffect(() => {
    document.title = `Thanks | Free Email Verifier`;
  }, []);
  return (
    <div className="thanks-container">
      <div className="thanks-content">
        <div className="thanks-check">
          <CheckIcon className="check-icon" />
        </div>
        <div className="thanks-heading">thank you!</div>
        {/* <div className="thanks-title">
          <p>
            established fact that a reader will be distracted established fact
            that a reader will be distracted established fact{" "}
          </p>
        </div> */}

        <Link to="/dashboard">
          <button className="thanks-button">Back to Home Page</button>
        </Link>
      </div>
    </div>
  );
}

export default Thanks;
