import React from "react";
import "../css/Contact.css";
const Contact = () => {
  return (
    <div className="contact-container">
      <div className="contact-title">
        <h1>Privacy Policy</h1>
      </div>
      <div className="contact-form-container">
        <div className="contact-form-content">
          <h3>1. An overview of data protection</h3>
          <h4>General information</h4>
          <p>
            The following information will provide you with an easy to navigate
            overview of what will happen with your personal data when you visit
            our website. The term &ldquo;personal data&rdquo; comprises all data
            that can be used to personally identify you. For detailed
            information about the subject matter of data protection, please
            consult our Data Protection Declaration, which we have included
            beneath this copy.
          </p>
          <h4>Data recording on our website</h4>
          <p>
            <strong>
              Who is the responsible party for the recording of data on this
              website (i.e. the &ldquo;controller&rdquo;)?
            </strong>
          </p>
          <p>
            The data on this website is processed by the operator of the
            website, whose contact information is available under section
            &ldquo;Information Required by Law&rdquo; on this website.
          </p>
          <p>
            <strong>How do we record your data?</strong>
          </p>
          <p>
            We collect your data as a result of your sharing of your data with
            us. This may, for instance be information you enter into our contact
            form.
          </p>
          <p>
            Our IT systems automatically record other data when you visit our
            website. This data comprises primarily technical information (e.g.
            web browser, operating system or time the site was accessed). This
            information is recorded automatically when you access our website.
          </p>
          <p>
            <strong>What are the purposes we use your data for?</strong>
          </p>
          <p>
            A portion of the information is generated to guarantee the error
            free provision of the website. Other data may be used to analyse
            your user patterns.
          </p>
          <p>
            <strong>
              What rights do you have as far as your information is concerned?
            </strong>
          </p>
          <p>
            You have the right to receive information about the source,
            recipients and purposes of your archived personal data at any time
            without having to pay a fee for such disclosures. You also have the
            right to demand that your data are rectified, blocked or eradicated.
            Please do not hesitate to contact us at any time under the address
            disclosed in section &ldquo;Information Required by Law&rdquo; on
            this website if you have questions about this or any other data
            protection related issues. You also have the right to log a
            complaint with the competent supervising agency.
          </p>
          <p>
            Moreover, under certain circumstances, you have the right to demand
            the restriction of the processing of your personal data. For
            details, please consult the Data Protection Declaration under
            section &ldquo;Right to Restriction of Data Processing.&rdquo;
          </p>
          <h4>Analysis tools and tools provided by third parties</h4>
          <p>
            There is a possibility that your browsing patterns will be
            statistically analysed when your visit our website. Such analyses
            are performed primarily with cookies and with what we refer to as
            analysis programmes. As a rule, the analyses of your browsing
            patterns are conducted anonymously; i.e. the browsing patterns
            cannot be traced back to you. You have the option to object to such
            analyses or you can prevent their performance by not using certain
            tools. For detailed information about this, please consult our Data
            Protection Declaration below.
          </p>
          <p>
            You do have the option to object to such analyses. We will brief you
            on the objection options in this Data Protection Declaration.
          </p>
          <h3>2. General information and mandatory information</h3>
          <h4>Data protection</h4>
          <p>
            The operators of this website and its pages take the protection of
            your personal data very seriously. Hence, we handle your personal
            data as confidential information and in compliance with the
            statutory data protection regulations and this Data Protection
            Declaration.
          </p>
          <p>
            Whenever you use this website, a variety of personal information
            will be collected. Personal data comprises data that can be used to
            personally identify you. This Data Protection Declaration explains
            which data we collect as well as the purposes we use this data for.
            It also explains how, and for which purpose the information is
            collected.
          </p>
          <p>
            We herewith advise you that the transmission of data via the
            Internet (i.e. through e-mail communications) may be prone to
            security gaps. It is not possible to completely protect data against
            third party access.
          </p>
          <h4>
            Information about the responsible party (referred to as the
            &ldquo;controller&rdquo; in the GDPR)
          </h4>
          <p>The data processing controller on this website is:</p>
          <p>
            <span class="bfsctd">Free-EmailVerifier</span>
            <br />
            <span class="bfsctd">99 Wall Street #112</span>
            <br />
            <span class="bfsctd">New York</span>
            <br />
            <span class="bfsctd">NY 10005</span>
            <br />
            <br />
            <br />
            Email: <span class="bfsctd">privacy@free-emailverifier.com</span>
          </p>
          <p>
            The controller is the natural person or legal entity that
            single-handedly or jointly with others makes decisions as to the
            purposes of and resources for the processing of personal data (e.g.
            names, e-mail addresses, etc.).
          </p>
          <h4>Revocation of your consent to the processing of data</h4>
          <p>
            A wide range of data processing transactions are possible only
            subject to your express consent. You can also revoke at any time any
            consent you have already given us. To do so, all you are required to
            do is sent us an informal notification via e-mail. This shall be
            without prejudice to the lawfulness of any data collection that
            occurred prior to your revocation.
          </p>
          <h4>
            Right to object to the collection of data in special cases; right to
            object to direct advertising (Art. 21 GDPR)
          </h4>
          <p>
            <strong>
              In the event that data are processed on the basis of Art. 6 Sect.
              1 lit. e or f GDPR, you have the right to at any time object to
              the processing of your personal data based on grounds arising from
              your unique situation. This also applies to any profiling based on
              these provisions. To determine the legal basis, on which any
              processing of data is based, please consult this Data Protection
              Declaration. If you log an objection, we will no longer process
              your affected personal data, unless we are in a position to
              present compelling protection worthy grounds for the processing of
              your data, that outweigh your interests, rights and freedoms or if
              the purpose of the processing is the claiming, exercising or
              defence of legal entitlements (objection pursuant to Art. 21 Sect.
              1 GDPR).
            </strong>
          </p>
          <p>
            <strong>
              If your personal data is being processed in order to engage in
              direct advertising, you have the right to at any time object to
              the processing of your affected personal data for the purposes of
              such advertising. This also applies to profiling to the extent
              that it is affiliated with such direct advertising. If you object,
              your personal data will subsequently no longer be used for direct
              advertising purposes (objection pursuant to Art. 21 Sect. 2 GDPR).
            </strong>
          </p>
          <h4>
            Right to log a complaint with the competent supervisory agency
          </h4>
          <p>
            In the event of violations of the GDPR, data subjects are entitled
            to log a complaint with a supervisory agency, in particular in the
            member state where they usually maintain their domicile, place of
            work or at the place where the alleged violation occurred. The right
            to log a complaint is in effect regardless of any other
            administrative or court proceedings available as legal recourses.
          </p>
          <h4>Right to data portability</h4>
          <p>
            You have the right to demand that we hand over any data we
            automatically process on the basis of your consent or in order to
            fulfil a contract be handed over to you or a third party in a
            commonly used, machine readable format. If you should demand the
            direct transfer of the data to another controller, this will be done
            only if it is technically feasible.
          </p>
          <h4>SSL and/or TLS encryption</h4>
          <p>
            For security reasons and to protect the transmission of confidential
            content, such as purchase orders or inquiries you submit to us as
            the website operator, this website uses either an SSL or a TLS
            encryption programme. You can recognise an encrypted connection by
            checking whether the address line of the browser switches from
            &ldquo;http://&rdquo; to &ldquo;https://&rdquo; and also by the
            appearance of the lock icon in the browser line.
          </p>
          <p>
            If the SSL or TLS encryption is activated, data you transmit to us
            cannot be read by third parties.
          </p>
          <h4>
            Information about, blockage, rectification and eradication of data
          </h4>
          <p>
            Within the scope of the applicable statutory provisions, you have
            the right to at any time demand information about your archived
            personal data, their source and recipients as well as the purpose of
            the processing of your data. You may also have a right to have your
            data rectified, blocked or eradicated. If you have questions about
            this subject matter or any other questions about personal data,
            please do not hesitate to contact us at any time at the address
            provided in section &ldquo;Information Required by Law.&rdquo;
          </p>
          <h4>Right to demand processing restrictions</h4>
          <p>
            You have the right to demand the imposition of restrictions as far
            as the processing of your personal data is concerned. To do so, you
            may contact us at any time at the address provided in section
            &ldquo;Information Required by Law.&rdquo; The right to demand
            restriction of processing applies in the following cases:
          </p>
          <ul>
            <li>
              In the event that you should dispute the correctness of your data
              archived by us, we will usually need some time to verify this
              claim. During the time that this investigation is ongoing, you
              have the right to demand that we restrict the processing of your
              personal data.
            </li>
            <li>
              If the processing of your personal data was/is conducted in an
              unlawful manner, you have the option to demand the restriction of
              the processing of your data in lieu of demanding the eradication
              of this data.
            </li>
            <li>
              If we do not need your personal data any longer and you need it to
              exercise, defend or claim legal entitlements, you have the right
              to demand the restriction of the processing of your personal data
              instead of its eradication.
            </li>
            <li>
              If you have raised an objection pursuant to Art. 21 Sect. 1 GDPR,
              your rights and our rights will have to be weighed against each
              other. As long as it has not been determined whose interests
              prevail, you have the right to demand a restriction of the
              processing of your personal data.
            </li>
          </ul>
          <p>
            If you have restricted the processing of your personal data, these
            data &ndash; with the exception of their archiving &ndash; may be
            processed only subject to your consent or to claim, exercise or
            defend legal entitlements or to protect the rights of other natural
            persons or legal entities or for important public interest reasons
            cited by the European Union or a member state of the EU.
          </p>
          <h3>3. Recording of data on our website</h3>
          <h4>Cookies</h4>
          <p>
            In some instances, our website and its pages use so-called cookies.
            Cookies do not cause any damage to your computer and do not contain
            viruses. The purpose of cookies is to make our website more user
            friendly, effective and more secure. Cookies are small text files
            that are placed on your computer and stored by your browser.
          </p>
          <p>
            Most of the cookies we use are so-called &ldquo;session
            cookies.&rdquo; They are automatically deleted after your leave our
            site. Other cookies will remain archived on your device until you
            delete them. These cookies enable us to recognise your browser the
            next time you visit our website.
          </p>
          <p>
            You can adjust the settings of your browser to make sure that you
            are notified every time cookies are placed and to enable you to
            accept cookies only in specific cases or to exclude the acceptance
            of cookies for specific situations or in general and to activate the
            automatic deletion of cookies when you close your browser. If you
            deactivate cookies, the functions of this website may be limited.
          </p>
          <p>
            Cookies that are required for the performance of the electronic
            communications transaction or to provide certain functions you want
            to use (e.g. the shopping cart function), are stored on the basis of
            Art. 6 Sect. 1 lit. f GDPR. The website operator has a legitimate
            interest in storing cookies to ensure the technically error free and
            optimised provision of the operator&rsquo;s services. If other
            cookies (e.g. cookies for the analysis of your browsing patterns)
            should be stored, they are addressed separately in this Data
            Protection Declaration.
          </p>
          <p>
            You can revoke your consent for cookies from our website at any time
            by visiting the{" "}
            <a href="https://www.verifyemailaddress.org/cookie-control.html">
              Cookie Control Center
            </a>
            .
          </p>
          <h4>Server log files</h4>
          <p>
            The provider of this website and its pages automatically collects
            and stores information in so-called server log files, which your
            browser communicates to us automatically. The information comprises:
          </p>
          <ul>
            <li>The type and version of browser used</li>
            <li>The used operating system</li>
            <li>Referrer URL</li>
            <li>The hostname of the accessing computer</li>
            <li>The time of the server inquiry</li>
            <li>The IP address</li>
          </ul>
          <p>This data is not merged with other data sources.</p>
          <p>
            This data is recorded on the basis of Art. 6 Sect. 1 lit. f GDPR.
            The operator of the website has a legitimate interest in the
            technically error free depiction and the optimization of the
            operator&rsquo;s website. In order to achieve this, server log files
            must be recorded.
          </p>
          <h4>Request by e-mail, telephone or fax</h4>
          <p>
            If you contact us by e-mail, telephone or fax, your request,
            including all resulting personal data (name, request) will be stored
            and processed by us for the purpose of processing your request. We
            do not pass these data on without your consent.
          </p>
          <p>
            The processing of these data is based on Art. 6 para. 1 lit. b GDPR,
            if your request is related to the execution of a contract or if it
            is necessary to carry out pre-contractual measures. In all other
            cases, the processing is based on your consent (Article 6 (1) a
            GDPR) and/or on our legitimate interests (Article 6 (1) (f) GDPR),
            since we have a legitimate interest in the effective processing of
            requests addressed to us.
          </p>
          <p>
            The data sent by you to us via contact requests remain with us until
            you request us to delete, revoke your consent to the storage or the
            purpose for the data storage lapses (e.g. after completion of your
            request). Mandatory statutory provisions - in particular statutory
            retention periods - remain unaffected.
          </p>
          <h3>4. Social media</h3>
          <h4>Social media plug-ins with Shariff</h4>
          <p>
            We do use plug-ins of social media networks on our website and its
            pages (e.g. Facebook, Twitter, Google+, Instagram, Pinterest, XING,
            LinkedIn, Tumblr).
          </p>
          <p>
            As a rule, you will be able to recognise these plug-ins because of
            the respective social media logos that appear. To warrant the
            protection of data on our website, we use these plug-ins only in
            combination with the so-called &ldquo;Shariff&rdquo; solution. This
            application prevents the plug-ins that have been integrated into our
            website from transferring data to the respective provider as soon as
            you enter our website.
          </p>
          <p>
            A direct connection to the provider&#39;s server shall not be
            established until you have activated the respective plug-in by
            clicking on the affiliated button (which indicates your consent). As
            soon as you activate the plug-in, the respective provider receives
            the information that you have visited our website with your IP
            address. If you are simultaneously logged into your respective
            social media account (e.g. Facebook), the respective provider will
            be able to allocate your visit to our website to your user account.
          </p>
          <p>
            The activation of the plug-in constitutes a declaration of consent
            as defined in Art. 6 Sect. 1 lit. a GDPR. You have the option to
            revoke this consent at any time, which shall affect all future
            transactions.
          </p>
          <h3>5. Analysis tools and advertising</h3>
          <h4>Google Analytics</h4>
          <p>
            This website uses functions of the web analysis service Google
            Analytics. The provider of this service is Google Ireland Limited
            (&ldquo;Google&rdquo;), Gordon House, Barrow Street, Dublin 4,
            Ireland.
          </p>
          <p>
            Google Analytics uses so-called cookies. Cookies are text files,
            which are stored on your computer and that enable an analysis of the
            use of the website by users. The information generated by cookies on
            your use of this website is usually transferred to a Google server
            in the United States, where it is stored.
          </p>
          <p>
            The storage of Google Analytics cookies and the utilization of this
            analysis tool are based on Art. 6 Sect. 1 lit. f GDPR. The operator
            of this website has a legitimate interest in the analysis of user
            patterns to optimize both, the services offered online and the
            operator&rsquo;s advertising activities.
          </p>
          <p>
            <strong>IP anonymization</strong>
          </p>
          <p>
            On this website, we have activated the IP anonymization function. As
            a result, your IP address will be abbreviated by Google within the
            member states of the European Union or in other states that have
            ratified the Convention on the European Economic Area prior to its
            transmission to the United States. The full IP address will be
            transmitted to one of Google&rsquo;s servers in the United States
            and abbreviated there only in exceptional cases. On behalf of the
            operator of this website, Google shall use this information to
            analyse your use of this website to generate reports on website
            activities and to render other services to the operator of this
            website that are related to the use of the website and the Internet.
            The IP address transmitted in conjunction with Google Analytics from
            your browser shall not be merged with other data in Google&rsquo;s
            possession.
          </p>
          <p>
            <strong>Browser plug-in</strong>
          </p>
          <p>
            You do have the option to prevent the archiving of cookies by making
            pertinent changes to the settings of your browser software. However,
            we have to point out that in this case you may not be able to use
            all of the functions of this website to their fullest extent.
            Moreover, you have the option prevent the recording of the data
            generated by the cookie and affiliated with your use of the website
            (including your IP address) by Google as well as the processing of
            this data by Google by downloading and installing the browser
            plug-in available under the following link:{" "}
            <a
              href="https://tools.google.com/dlpage/gaoptout?hl=en"
              rel="noopener"
              target="_blank"
            >
              https://tools.google.com/dlpage/gaoptout?hl=en
            </a>
            .
          </p>
          <p>
            <strong>Objection to the recording of data</strong>
          </p>
          <p>
            You have the option to prevent the recording of your data by Google
            Analytics by clicking on the following link. This will result in the
            placement of an opt out cookie, which prevents the recording of your
            data during future visits to this website:{" "}
            <a>Google Analytics deactivation</a>.
          </p>
          <p>
            For more information about the handling of user data by Google
            Analytics, please consult Google&rsquo;s Data Privacy Declaration
            at:{" "}
            <a
              href="https://support.google.com/analytics/answer/6004245?hl=de"
              rel="noopener"
              target="_blank"
            >
              https://support.google.com/analytics/answer/6004245?hl=en
            </a>
            .
          </p>
          <p>
            <strong>Contract data processing</strong>
          </p>
          <p>
            We have executed a contract data processing agreement with Google
            and are implementing the stringent provisions of the German data
            protection agencies to the fullest when using Google Analytics.
          </p>
          <p>
            <strong>Archiving period</strong>
          </p>
          <p>
            Data on the user or incident level stored by Google linked to
            cookies, user IDs or advertising IDs (e.g. DoubleClick cookies,
            Android advertising ID) will be anonymized or deleted after 14
            month. For details please click the following link:{" "}
            <a
              href="https://support.google.com/analytics/answer/7667196?hl=de"
              rel="noopener"
              target="_blank"
            >
              https://support.google.com/analytics/answer/7667196?hl=de
            </a>
          </p>
          <h4>Google AdSense</h4>
          <h5>Visitors within the EEA (non-personalized ads)</h5>
          <p>
            This website uses Google AdSense, an ad embedding service provided
            by Google Ireland Limited (&ldquo;Google&rdquo;), Gordon House,
            Barrow Street, Dublin 4, Ireland.
          </p>
          <p>
            We use Google AdSense in the &ldquo;non-personalized&rdquo; mode.
            Contrary to the personalized mode, the ads are not based on your
            previous user patterns and the service does not generate a user
            profile for you. Instead, the service uses so-called &ldquo;context
            information&rdquo; to choose the ads that are posted for you to
            view. The selected ads are thus based e.g. on your location, the
            content of the website you are visiting at the time or the search
            terms you are using. To learn more about the distinct differences
            between personalized targeting and targeting that has not been
            personalized by Google AdSense, please click on the following link:{" "}
            <a
              href="https://support.google.com/adsense/answer/9007336"
              rel="noopener"
              target="_blank"
            >
              https://support.google.com/adsense/answer/9007336
            </a>
            .
          </p>
          <p>
            Please keep in mind that even the use of the non-personalized mode
            of Google Adsense results in the potential archiving of cookies.
            According to Google, these cookies are used to combat fraud and
            misuse. These cookies will remain on your device until you delete
            them.
          </p>
          <p>
            The use of Google Adsense in the non-personalized mode is based on
            Art. 6 Sect. 1 lit. f GDRP. We have a justified interest in the
            analysis of user patterns to be able to optimize both, our web
            presentation and our promotional activities.
          </p>
          <p>
            Google is certified in accordance with the &ldquo;EU-US Privacy
            Shield.&rdquo; The Privacy Shield accord was executed between the
            European Union and the United States with the aim of ensuring that
            European Data Protection Standards are observed in conjunction with
            the processing of data in the United States. Every company that is
            certified in accordance with the Privacy Shield regulations
            undertakes to comply with these data protection standards. &nbsp;
          </p>
          <p>
            You have the option to autonomously adjust the advertising settings
            in your user account. To do so, please click on the link provided
            below and log in:{" "}
            <a
              href="https://adssettings.google.com/authenticated"
              rel="noopener"
              target="_blank"
            >
              https://adssettings.google.com/authenticated
            </a>
            .
          </p>
          <p>
            For more information about Google&rsquo;s advertising technologies,
            please click here:
            <br />
            <a
              href="https://policies.google.com/technologies/ads"
              rel="noopener"
              target="_blank"
            >
              https://policies.google.com/technologies/ads
            </a>
            <br />
            <a
              href="https://www.google.de/intl/de/policies/privacy/"
              rel="noopener"
              target="_blank"
            >
              https://www.google.de/intl/de/policies/privacy/
            </a>
            .
          </p>
          <h5>Visitors outside the EEA</h5>
          <p>
            This website uses Google AdSense, a service for the integration of
            ads. The provider of this service is Google Ireland Limited
            (&ldquo;Google&rdquo;), Gordon House, Barrow Street, Dublin 4,
            Ireland.
          </p>
          <p>
            Google AdSense uses so-called &quot;cookies&quot;; i.e. text files
            that are stored on your computer and that make it possible to
            analyse your use of this website. Google AdSense also uses so-called
            Web Beacons (invisible graphics). These Web Beacons can be used to
            analyse information, such as visitor traffic data, on this website.
          </p>
          <p>
            The information generated by the cookies and the Web Beacons
            concerning the use of this website (including your IP address) and
            the delivery of advertising formats are transferred to a Google
            server in the United States, where they are archived. Google may
            share this information with Google contracting partners. Google
            shall, however, not merge your IP address with any other of your
            data stored by Google.
          </p>
          <p>
            The storage of the AdSense cookies is based on Art. 6 Sect. 1 lit. f
            GDPR. The website operator has a legitimate interest in the analysis
            of user patterns, in order to optimize the operator&rsquo;s web
            offerings and advertising.
          </p>
          <p>
            You can prevent the installation of these cookies by making a
            pertinent adjustment to your browser software settings; however, we
            have to point out that in this case you may not be able to use all
            of the functions of this website to their fullest extent. By using
            this website, you declare your consent to the processing of the data
            we collect about you by Google in the manner described above and
            agree to the aforementioned purpose of use.
          </p>
          <h3>6. Plug-ins and Tools</h3>
          <h4>YouTube</h4>
          <p>
            Our website uses plug-ins of the YouTube platform, which is operated
            by Google. The website operator is Google Ireland Limited
            (&ldquo;Google&rdquo;), Gordon House, Barrow Street, Dublin 4,
            Ireland.
          </p>
          <p>
            If you visit a page on our website into which a YouTube plug-in has
            been integrated, a connection with YouTube&rsquo;s servers will be
            established. As a result, the YouTube server will be notified, which
            of our pages you have visited.
          </p>
          <p>
            Furthermore, YouTube will be able to place various cookies on your
            device. With the assistance of these cookies, YouTube will be able
            to obtain information about our website visitor. Among other things,
            this information will be used to generate video statistics with the
            aim of improving the user friendliness of the site and to prevent
            attempts to commit fraud. These cookies will stay on your device
            until you delete them.
          </p>
          <p>
            If you are logged into your YouTube account while you visit our
            site, you enable YouTube to directly allocate your browsing patterns
            to your personal profile. You have the option to prevent this by
            logging out of your YouTube account.
          </p>
          <p>
            The use of YouTube is based on our interest in presenting our online
            content in an appealing manner. Pursuant to Art. 6 Sect. 1 lit. f
            GDPR, this is a legitimate interest.
          </p>
          <p>
            For more information on how YouTube handles user data, please
            consult the YouTube Data Privacy Policy under:{" "}
            <a
              href="https://policies.google.com/privacy?hl=en"
              rel="noopener"
              target="_blank"
            >
              https://policies.google.com/privacy?hl=en
            </a>
            .
          </p>
          <h4>Google reCAPTCHA</h4>
          <p>
            We use &ldquo;Google reCAPTCHA&rdquo; (hereinafter referred to as
            &ldquo;reCAPTCHA&rdquo;) on our websites. The provider is Google
            Ireland Limited (&ldquo;Google&rdquo;), Gordon House, Barrow Street,
            Dublin 4, Ireland.
          </p>
          <p>
            The purpose of reCAPTCHA is to determine whether data entered on our
            websites (e.g. information entered into a contact form) is being
            provided by a human user or by an automated program. To determine
            this, reCAPTCHA analyses the behaviour of the website visitors based
            on a variety of parameters. This analysis is triggered automatically
            as soon as the website visitor enters the site. For this analysis,
            reCAPTCHA evaluates a variety of data (e.g. IP address, time the
            website visitor spent on the site or cursor movements initiated by
            the user). The data tracked during such analyses are forwarded to
            Google.
          </p>
          <p>
            reCAPTCHA analyses run entirely in the background. Website visitors
            are not alerted that an analysis is underway.
          </p>
          <p>
            The data is processed on the basis of Art. 6 Sect. 1 lit. f GDPR. It
            is in the website operators legitimate interest, to protect the
            operator&rsquo;s web content against misuse by automated industrial
            espionage systems and against SPAM.
          </p>
          <p>
            For more information about Google reCAPTCHA and to review the Data
            Privacy Declaration of Google, please follow these links:{" "}
            <a
              href="https://policies.google.com/privacy?hl=en"
              rel="noopener"
              target="_blank"
            >
              https://policies.google.com/privacy?hl=en
            </a>{" "}
            and{" "}
            <a
              href="https://www.google.com/recaptcha/intro/android.html"
              rel="noopener"
              target="_blank"
            >
              https://www.google.com/recaptcha/intro/android.html
            </a>
            .
          </p>
          <h3>7. Ezoic</h3>
          <div class="et_pb_tab_content">
            <h3>
              <span id="ezoic-privacy-policy-embed">
                <a href="https://www.verifyemailaddress.org/?ez_force_cookie_consent=1">
                  <u>CLICK HERE TO CHANGE YOUR PRIVACY CONSENT SETTINGS</u>
                </a>
              </span>
            </h3>
            <p>
              <span id="ezoic-privacy-policy-embed">
                <span>
                  <strong>EZOIC SERVICE PRIVACY POLICY</strong>
                </span>
              </span>
            </p>
            <p>
              <span id="ezoic-privacy-policy-embed">
                <span>
                  verifyemailaddress.org (&quot;Website&quot;) uses a third
                  party technology called Ezoic.
                </span>
              </span>
            </p>
            <p>
              <span id="ezoic-privacy-policy-embed">
                <span>INFORMATION ABOUT OUR ORGANIZATION AND WEBSITE</span>
              </span>
            </p>
            <p>
              <span id="ezoic-privacy-policy-embed">
                <span>
                  <strong>
                    Ezoic is committed to protecting your privacy. We will only
                    use the information that we collect lawfully in accordance
                    with the General Data Protection Regulation (GDPR)
                    (Regulation (EU) 2016/679).
                  </strong>
                </span>
              </span>
            </p>
            <p>
              <span id="ezoic-privacy-policy-embed">
                <span>Ezoic&#39;s principal activities are:</span>
              </span>
            </p>
            <ul>
              <li>
                <span id="ezoic-privacy-policy-embed">
                  <span>Website analytics</span>
                </span>
              </li>
              <li>
                <span id="ezoic-privacy-policy-embed">
                  <span>Website personalization</span>
                </span>
              </li>
              <li>
                <span id="ezoic-privacy-policy-embed">
                  <span>Website hosting</span>
                </span>
              </li>
            </ul>
            <p>
              <span id="ezoic-privacy-policy-embed">
                <span>
                  Our privacy policy covers Ezoic Inc., Ezoic Limited and this
                  Website:
                </span>
              </span>
            </p>
            <p>
              <span id="ezoic-privacy-policy-embed">
                <span>Ezoic Inc.</span>
              </span>
            </p>
            <p>
              <span id="ezoic-privacy-policy-embed">
                <span>
                  6023 Innovation Way, Carlsbad, California, United States
                </span>
              </span>
            </p>
            <p>
              <span id="ezoic-privacy-policy-embed">
                <span>Ezoic Limited</span>
              </span>
            </p>
            <p>
              <span id="ezoic-privacy-policy-embed">
                <span>
                  Northern Design Centre, Abbott&#39;s Hill, Gateshead, NE8 3DF
                  United Kingdom
                </span>
              </span>
            </p>
            <p>
              <span id="ezoic-privacy-policy-embed">
                <span>
                  <strong>DATA</strong>
                </span>
              </span>
            </p>
            <p>
              <span id="ezoic-privacy-policy-embed">
                <span>
                  In order to provide our products and services and related
                  support, it is necessary for Ezoic to transfer your personal
                  data outside of the European Union.&nbsp; In such case, the
                  data controller for this data for EU residents is Ezoic Inc.
                  having its registered offices at 6023 Innovation Way,
                  Carlsbad, California, United States.&nbsp; All questions of
                  requests regarding the processing of data may be addresses to
                  data@ezoic.com
                </span>
              </span>
            </p>
            <p>
              <span id="ezoic-privacy-policy-embed">
                <span>
                  <strong>PROVIDING VISITORS WITH ANONYMOUS ACCESS</strong>
                </span>
              </span>
            </p>
            <p>
              <span id="ezoic-privacy-policy-embed">
                <span>
                  If you do not consent to such collection, you can access this
                  website without disclosing your personal data.
                </span>
              </span>
            </p>
            <p>
              <span id="ezoic-privacy-policy-embed">
                <span>
                  <strong>AUTOMATIC COLLECTION OF INFORMATION</strong>
                </span>
              </span>
            </p>
            <p>
              <span id="ezoic-privacy-policy-embed">
                <span>
                  Ezoic records data about the individuals and the traffic to
                  this website. Ezoic is this website&#39;s limited agent (and
                  data controller in the EU context) for the purpose of
                  providing Internet data and optimization services. Ezoic may
                  use this data to improve its service or enable other services
                  (e.g., using visitor traffic logs or data posted through the
                  service to improve the optimization of other websites).
                </span>
              </span>
            </p>
          </div>
          <p>
            <span>
              <strong>Personal Information</strong>
            </span>
          </p>
          <p>
            <span>
              Ezoic may collect personal information as defined in GDPR (such as
              IP address and a unique id in a cookie) about visitors to your
              website for the purposes of statistics, analytics and
              personalization. In addition, Ezoic works with numerous third
              parties for the collection and storage of data and the providing
              of analytics and advertising services.
            </span>
          </p>
          <p>
            <span>
              <strong>Use of Personal Information</strong>
            </span>
          </p>
          <figure class="table">
            <table>
              <tbody>
                <tr>
                  <td>
                    <span>
                      <strong>Purpose/Activity</strong>
                    </span>
                  </td>
                  <td>
                    <span>
                      <strong>Type(s) of Data</strong>
                    </span>
                  </td>
                  <td>
                    <span>
                      <strong>Lawful basis for processing</strong>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>To improve user experience</span>
                  </td>
                  <td>
                    <ul>
                      <li>
                        <span>Identity</span>
                      </li>
                      <li>
                        <span>Technical</span>
                      </li>
                      <li>
                        <span>Usage</span>
                      </li>
                    </ul>
                  </td>
                  <td>
                    <span>
                      Your information helps us to provide you with a more
                      pleasant, efficient experience on this website that may be
                      customized to how you or others respond to content and ads
                      on this or other websites.
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>To improve ad performance</span>
                  </td>
                  <td>
                    <ul>
                      <li>
                        <span>Identity</span>
                      </li>
                      <li>
                        <span>Technical</span>
                      </li>
                      <li>
                        <span>Usage</span>
                      </li>
                    </ul>
                  </td>
                  <td>
                    <span>
                      Your information helps us to optimize the placement, size,
                      timing and number of ads displayed to you. It enables us
                      to make smart decision that help this website owner earn
                      money but reduce the disruption of ads on your experience.
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>To improve content creation</span>
                  </td>
                  <td>
                    <ul>
                      <li>
                        <span>Identity</span>
                      </li>
                      <li>
                        <span>Technical</span>
                      </li>
                      <li>
                        <span>Usage</span>
                      </li>
                    </ul>
                  </td>
                  <td>
                    <span>
                      Your information helps us learn what content you care
                      about and enjoy and, the style of content you like and
                      what you engage the most with. This helps us produce more
                      content and features that you might like.
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>To improve website performance</span>
                  </td>
                  <td>
                    <ul>
                      <li>
                        <span>Identity</span>
                      </li>
                      <li>
                        <span>Technical</span>
                      </li>
                      <li>
                        <span>Usage</span>
                      </li>
                    </ul>
                  </td>
                  <td>
                    <span>
                      Your information helps us measure various performance
                      metrics about our site, such as site speed, so that we can
                      improve the performance of this site.
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </figure>
          <p>
            <span>&nbsp;</span>
          </p>
          <p>
            <span>
              <strong>PROCESSING OF DATA AND CONSENT</strong>
            </span>
          </p>
          <p>
            <span>
              We will process the data we collect about you to improve the
              operation of this and other websites. This information helps us
              make decisions about what content to show, how it should be
              formatted, the number, size and placement of advertisements and
              how content should be delivered to individuals. This information
              is also used for analysis of performance and reporting.
            </span>
          </p>
          <p>
            <span>
              <strong>USE OF APPLICATION LOGS</strong>
            </span>
          </p>
          <p>
            <span>
              Our servers automatically record information (&ldquo;Application
              Log Data&rdquo;) created by your use of this website. Application
              Log Data may include information such as your IP address, browser
              type, operating system, the referring web page, pages visited,
              location, your mobile carrier, device and application IDs, search
              terms, and cookie information. We use this information to diagnose
              and improve our services. Except as stated in section (Data
              Retention), we will either delete the Application Log Data or
              remove any account identifiers, such as your username, full IP
              address, or email address, after 48 months.
            </span>
          </p>
          <p>
            <span>
              <strong>DATA RETENTION</strong>
            </span>
          </p>
          <p>
            <span>
              The personal information we collect is retained for no longer than
              necessary to fulfill the stated purposes in section
              &ldquo;PROCESSING OF DATA AND CONSENT&rdquo; above or for a period
              specifically required by law or regulation that Ezoic is obligated
              to follow. Personal data used to fulfill verification of certain
              types of services such as SSL certificate, payments, and billing
              will be retained for a minimum of 5 years depending on the class
              of product or service and may be retained in either a physical or
              electronic format. Even if you request deletion or erasure of your
              data, we may retain your personal data to the extent necessary and
              for so long as necessary for our legitimate business interests or
              performance of contractual obligations. After the retention period
              is over, Ezoic securely disposes or anonymizes your personal
              information in order to prevent loss, theft, misuse, or
              unauthorized access.
            </span>
          </p>
          <p>
            <span>
              <strong>CONFIDENTIALITY / SECURITY</strong>
            </span>
          </p>
          <p>
            <span>
              We have implemented security policies, rules and technical
              measures to protect the personal data that we have under our
              control from: unauthorized access improper use or disclosure
              unauthorized modification unlawful destruction or accidental loss.
              All our employees and data processors, who have access to, and are
              associated with the processing of personal data, are obliged to
              respect the confidentiality of our visitors&rsquo; personal data.
              We ensure that your personal data will not be disclosed to State
              institutions and authorities except if required by law or other
              regulation
            </span>
          </p>
          <p>
            <br />
            &nbsp;
          </p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
